import { Box, Flex } from 'rebass';
import { borderRadius, boxShadow } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const Outer = styled(Flex)`
  /* magic number is the height of app header */
  min-height: calc(100vh - 46px);
  ${props => props.theme.media.desktop`
    min-height: calc(100vh - 70px);
  `};
`;

const Inner = styled(Box)`
  ${boxShadow};
  ${borderRadius};
`;

const FullPageFormWrap = ({ children, innerBg }) => (
  <Outer bg="bgGrey" alignItems="center" justifyContent="center" p={[15, 30]}>
    <Inner
      bg={innerBg}
      width={['100%', 450]}
      borderRadius={12}
      boxShadow="2px 2px 4px rgba(0, 0, 0, 0.07)"
      p={30}
      pt={0}
    >
      {children}
    </Inner>
  </Outer>
);

FullPageFormWrap.propTypes = {
  children: PropTypes.node.isRequired,
  innerBg: PropTypes.string,
};

FullPageFormWrap.defaultProps = {
  innerBg: '#f7f7f7',
};

export default FullPageFormWrap;
