import { Text } from 'rebass';
import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../app/modal/v3';
import QuestionTooltip from '../app/modal/v3/question-tooltip';
import OwnerShares from './owner-shares';

const StoryShares = ({ storyId, onBack }) => {
  return (
    <Modal
      back={{ onBack }}
      title="Invite Participants"
      header={{
        right: (
          <QuestionTooltip>
            <Text color="black">
              When sharing a story, you can make someone a <b>viewer</b> or a <b>co-creator</b>.{' '}
              <b>Co-creators</b> can edit and add to the story!
            </Text>
          </QuestionTooltip>
        ),
      }}
      footerOffset={55}
    >
      <OwnerShares {...{ storyId, onBack }} />
    </Modal>
  );
};
StoryShares.propTypes = {
  storyId: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default StoryShares;
