import React from 'react';

import { SlideOne, SlideTwo, SlideThree, SlideFour } from './slides';
import { useHistory } from '../../app/hooks';
import CollectionOnboardCarousel from '../../collections/onboard/carousel';
import Modal from '../../app/modal/v2';

const CompleteOnboarding = () => {
  const { push } = useHistory();

  return (
    <Modal fullscreenMobile>
      <CollectionOnboardCarousel ctaText="Get Started" onClose={() => push('/shared-with-me')}>
        <SlideOne />
        <SlideTwo />
        <SlideThree />
        <SlideFour />
      </CollectionOnboardCarousel>
    </Modal>
  );
};

export default CompleteOnboarding;
