import * as types from './types';

export const setCheckmark = payload => ({
  type: types.SET_CHECKMARK,
  payload,
});

export const toggleNavigationVisible = visible => ({
  type: types.TOGGLE_NAVIGATION_VISIBILITY,
  visible,
});

export const clearCheckmark = () => ({
  type: types.CLEAR_CHECKMARK,
});

export const toggleConnectionStatus = state => ({ type: state });
