import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleNavigationVisible } from 'ui/actions';

export const useNavSuppressed = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleNavigationVisible(false));
    return () => {
      dispatch(toggleNavigationVisible(true));
    };
  }, [dispatch]);
};

export const useIsNavigationVisible = () => useSelector(state => state.ui.navigationVisible);

export default useNavSuppressed;
