import React, { useContext, useState } from 'react';
import { UAContext } from '@quentin-sommer/react-useragent';
import store from 'store';
import { Flex, Box, Text, Heading, Link, Button } from 'rebass';
import { FaInfoCircle } from 'react-icons/fa';

// colours taken from https://www.colorhexa.com/7a69bf tint variant
const Banner = ({ title, body, icon, children, ...props }) => (
  <Flex as={Box} alignItems="center" p={2} bg="brand" borderTop="1px solid" borderColor="border">
    <Text as={icon} color="#c3bbe2" size={24} mr={2} />
    <Box>
      <Heading color="white" fontSize={2} fontFamily="sanFrancisco">
        {title}
      </Heading>
      <Text color="#dcd7ee" fontSize={0}>
        {body}
      </Text>
    </Box>
    <Box ml="auto">{children}</Box>
  </Flex>
);

const AndroidSaveBanner = () => {
  const [seenSaveBanner, setSeenSaveBanner] = useState(
    (store.get('banners') || '').includes('androidSave')
  );
  const {
    uaResults: { android },
  } = useContext(UAContext);
  const onClick = () => {
    store.set('banners', 'androidSave');
    setSeenSaveBanner(true);
  };

  if (seenSaveBanner || !android) return false;

  return (
    <Banner title="Find your way back!" body="Save LifeTales to your desktop" icon={FaInfoCircle}>
      <Button
        as="a"
        fontSize={1}
        color="#6e5bb9"
        bg="white"
        mb={1}
        px={2}
        py={1}
        onClick={onClick}
        href="https://public.lifetales.com/story/fc07be40-5e30-46f5-baae-49e1480f75a6"
        target="_blank"
      >
        Learn More
      </Button>
      <Text fontSize={0} textAlign="center">
        <Link type="button" as="button" color="#cfc9e8" onClick={onClick}>
          Dismiss
        </Link>
      </Text>
    </Banner>
  );
};

export default AndroidSaveBanner;
