import React, { createContext, useState } from 'react';

export const CurrentModalContext = createContext();

const CurrentModal = props => {
  const [currentModal, setCurrentModal] = useState('');

  return <CurrentModalContext.Provider value={{ currentModal, setCurrentModal }} {...props} />;
};

export default CurrentModal;
