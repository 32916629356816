import { Formik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import posed, { PoseGroup } from 'react-pose';

import { Footer, MoreCommentsToggle } from './styled';
import { ScrollContext } from '../../contexts/scroll';
import { addMediumActivity } from '../api';
import { formatCommentBody } from './helper';
import { receiveMediumActivity } from '../actions';
import { selectCurrentMember } from '../../members/selectors';
import { selectPageComments } from '../../story-pages/selectors';
import { getStoryPages } from '../../story-pages/actions';
import { useToggle } from '../../app/hooks';
import Form from './form';
import MemberAvatar from '../../members/avatar';
import StoryPageComment from './story-page-comment';

const PosedDiv = posed.div();

const StoryPageComments = ({
  comments,
  storyId,
  memberId,
  pageId,
  mediumId,
  mediumType,
  userToggledComments,
  ...props
}) => {
  const { scrollItemId } = useContext(ScrollContext);
  const shouldShowToggle = comments.length > 3;
  const [open, toggle] = useToggle(comments.map(({ id }) => id).includes(scrollItemId));
  const lastThreeComments = comments.slice(Math.max(comments.length - 3, 1));
  const commentsRef = useRef();

  return (
    <>
      {shouldShowToggle && (
        <MoreCommentsToggle onClick={toggle}>
          {open ? 'SHOW ONLY RECENT COMMENTS' : `SHOW ALL COMMENTS (${comments.length})`}
        </MoreCommentsToggle>
      )}
      <PoseGroup>
        {open || !shouldShowToggle
          ? comments.map(({ id: commentId }) => (
              <PosedDiv key={commentId}>
                <StoryPageComment key={commentId} id={commentId} storyId={storyId} />
              </PosedDiv>
            ))
          : lastThreeComments.map(({ id: commentId }) => (
              <PosedDiv key={commentId}>
                <StoryPageComment key={commentId} id={commentId} storyId={storyId} />
              </PosedDiv>
            ))}
      </PoseGroup>
      <Footer ref={commentsRef}>
        <MemberAvatar id={memberId} size="45" />
        <Formik
          initialValues={{ text: '' }}
          onSubmit={(values, actions) => {
            addMediumActivity(mediumId, formatCommentBody(mediumId, memberId, values))
              .then(res => {
                props.receiveMediumActivity(res);
                actions.setSubmitting(false);
                actions.resetForm();
                props.getStoryPages(storyId);
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          }}
          component={Form}
        />
      </Footer>
    </>
  );
};

StoryPageComments.propTypes = {
  mediumId: PropTypes.string.isRequired,
  mediumType: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  receiveMediumActivity: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
  storyId: PropTypes.string.isRequired,
  userToggledComments: PropTypes.bool.isRequired,
  getStoryPages: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { pageId }) => {
  const { id: memberId } = selectCurrentMember(state);
  const comments = selectPageComments(state, pageId);
  const { id: mediumId, type: mediumType } = state.entities.pages[pageId].media[0];
  return {
    comments,
    memberId,
    mediumId,
    mediumType,
  };
};

export default connect(mapStateToProps, { receiveMediumActivity, getStoryPages })(
  StoryPageComments
);
