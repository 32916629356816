import { Box, Button, Flex, Heading } from 'rebass';
import React from 'react';

import { useHistory } from '../app/hooks';

const StoryDetailError = () => {
  const { push } = useHistory();

  return (
    <Flex css="min-height: 100vh">
      <Box bg="white" flex="0 1 420px" m="auto" p={3}>
        <Flex flexDirection="column" justifyContent="center">
          <Heading mb={3} textAlign="center">
            Something Went Wrong
          </Heading>
          <Button variant="primary" type="button" onClick={() => push('/my-stories')}>
            Back to Home
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default StoryDetailError;
