import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass';
import { FaVideo } from 'react-icons/fa';

const RecordChatButton = ({ onRecordChat }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    onRecordChat();
  };

  return (
    <Button
      fontWeight="normal"
      fontSize={2}
      flex={1}
      variant="muted"
      disabled={loading}
      onClick={handleClick}
      bg="#FFF9E6"
      color="#594E26"
      sx={{ borderRadius: 12, border: '1px solid #F0E5C2' }}
      py={3}
    >
      <Text as={FaVideo} size={32} />
      <Text>Record a Chat</Text>
      <Text fontSize={1}>(beta)</Text>
    </Button>
  );
};

RecordChatButton.propTypes = {
  onRecordChat: PropTypes.func.isRequired,
};

RecordChatButton.defaultProps = {
  onRecordChat: () => {},
};

export default RecordChatButton;
