import { DateTime } from 'luxon';

import { RECEIVE_ENTITY, REQUEST_ENTITIES } from '../entities/types';
import * as api from './api';
import * as types from './types';

export const toggleNewStoryVisible = (newStoryVisible = true) => ({
  type: types.TOGGLE_NEW_STORY_VISIBLE,
  newStoryVisible,
});

export const destroyStory = storyId => ({
  type: types.DESTROY_STORY,
  promise: api.destroyStory(storyId),
  meta: {
    storyId,
  },
});

export const getStory = storyId => ({
  type: types.GET_STORY,
  promise: api.getStory(storyId),
});

export const receiveMediumActivity = payload => ({
  type: RECEIVE_ENTITY,
  payload,
});

export const removeComment = (commentId, storyId) => ({
  type: types.REMOVE_MEDIUM_ACTIVITY,
  promise: api.removeMediumActivity(commentId),
  meta: {
    mediumActivityId: commentId,
    mediumActivityType: 'comments',
    storyId,
  },
});

export const likeStoryMedia = (mediumId, mediumType, storyId) => ({
  type: types.ADD_MEDIUM_ACTIVITY,
  promise: api.addMediumActivity(mediumId, { data: { attributes: { type: 'Like' } } }),
  meta: {
    mediumId,
    mediumType,
    mediumActivity: 'like-count',
    storyId,
  },
});

export const unlikeStoryMedia = (likeId, storyId) => ({
  type: types.REMOVE_MEDIUM_ACTIVITY,
  promise: api.removeMediumActivity(likeId, storyId),
  meta: {
    mediumActivityId: likeId,
    mediumActivityType: 'likes',
    storyId,
  },
});

export const updateComment = (commentId, body) => ({
  type: types.UPDATE_MEDIUM_ACTIVITY,
  promise: api.updateMediumActivity(commentId, {
    data: {
      id: commentId,
      attributes: body,
    },
  }),
  meta: {
    mediumActivityId: commentId,
    mediumActivityType: 'comments',
  },
});

export const createStory = (attrs = {}) => {
  const title = attrs.title || DateTime.local().toLocaleString(DateTime.DATE_FULL);

  return {
    type: REQUEST_ENTITIES,
    promise: api.createStory({ title, ...attrs }),
  };
};

export const updateStory = (id, attributes) => ({
  type: REQUEST_ENTITIES,
  promise: api.updateStory(id, attributes),
});

export const getPublicStory = (memberId, storyId) => ({
  type: types.GET_PUBLIC_STORY,
  promise: api.getPublicStory(memberId, storyId),
});

export const setScrollPosition = scrollPosition => ({
  type: types.SET_SCROLL_POSITION,
  scrollPosition,
});

export const downloadStory = storyId => ({
  type: types.EXPORT_STORY,
  promise: api.downloadStory(storyId),
  meta: {
    storyId,
  },
});

export const shareStory = storyId => ({
  type: types.SHARE_STORY,
  promise: api.shareStory(storyId),
});
