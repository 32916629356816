import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, Text } from 'rebass';
import Modal from 'app/modal/v3/index';

const StoryShareInfo = ({ onBack }) => (
  <Modal title="Story Shares" back={{ onBack }}>
    <Box p={4}>
      <Heading fontFamily="sans" pb={2} fontSize={3}>
        Share with LifeTales contacts
      </Heading>
      <Text pb={4}>
        Share privately with your LifeTales contacts. Choose as many friends as you&rsquo;d like.
        You can also make any friend a Collaborator — that will give them the ability to edit/add to
        the story.
      </Text>
      <Heading fontFamily="sans" pb={2} fontSize={3}>
        Send a link to non-LifeTales contacts
      </Heading>
      <Text pb={4}>
        Send this link to your friends by email, messaging apps or social media. Anyone with the
        link will be able to view the story in their browser. They&rsquo;ll also have the option to
        create a LifeTales account and connect to you.
      </Text>

      <Heading fontFamily="sans" pb={2} fontSize={3}>
        Share via LifeTales Collection
      </Heading>
      <Text pb={2}>
        Add this story to one or more Collections. It will be visible to all participants in the
        selected Collections.
      </Text>
    </Box>
  </Modal>
);
StoryShareInfo.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default StoryShareInfo;
