import { Formik } from 'formik';
import { object, string } from 'yup';
import React from 'react';

import { postChildJournal, destroyChildJournal } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionName from '../../collections/new/name';

const ChildJournalName = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: childJournal } = useEntity({ ...params });
  const { receive, receiveDeletion } = useEntityActionCreators();
  const initialValues = {
    'first-name-noac': (childJournal && childJournal['first-name']) || '',
  };
  const validationSchema = object().shape({
    'first-name-noac': string().required(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <FullPageForm
      onExitClick={() => {
        if (childJournal && childJournal.id) {
          destroyChildJournal(childJournal.id)
            .then(() => receiveDeletion({ entityType: 'child-journals', id: childJournal.id }))
            .then(() => push('/my-stories'));
        } else {
          push('/my-stories');
        }
      }}
      title="Create a Child Journal"
    >
      <Formik
        {...{ initialValues, validationSchema, isInitialValid }}
        onSubmit={async values => {
          const attributes = prepareAttrs(values);
          // this attribute is required because of the way that the iOS app set stuff up
          attributes['subject-relation'] = 'Child';
          const body = {
            data: {
              attributes,
            },
          };

          // POST child journal
          const cjRes = await postChildJournal(body);
          const [id] = cjRes.data.result['child-journals'];
          receive({ payload: cjRes });
          push(`/child-journals/new/${id}/date-of-birth`);
        }}
        render={props => (
          <NewCollectionName
            label="Name of Your Child"
            placeholder="E.g. Jane or John"
            {...props}
          />
        )}
      />
    </FullPageForm>
  );
};

export default ChildJournalName;
