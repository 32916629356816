import { Box, Flex, Text } from 'rebass';
import { FaSyncAlt, FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { keyframes } from '@emotion/core';
import { pluralize } from 'inflected';
import { useDispatch } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { clearUploadBatch } from '../actions';
import { useUploadBatchCounts } from './hooks';
import UploadManagerModal from './modal';
import UploadManagerToast from './toast';

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

const UploadManager = () => {
  const { total, success, error, uploading } = useUploadBatchCounts();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    setShowBar(!!total);
  }, [total]);

  const uploadState = useMemo(() => {
    if (!total) return {};
    if (success === total) {
      return { success: true };
    }
    if (error) return { error: true };
    if (total) return { uploading: true };
    return {};
  }, [total, error, success]);

  const uploadMessage = useMemo(() => {
    if (total === 1) {
      if (uploading) {
        return 'Uploading';
      }
      return 'Success';
    }
    return `Uploaded ${success} of ${total}`;
  }, [uploading, success, total]);

  useEffect(() => {
    let timer;
    if (uploadState.success) {
      timer = setTimeout(() => setShowBar(false), 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [uploadState.success]);

  const showModalRef = useRef(showModal);
  useEffect(() => {
    showModalRef.current = showModal;
  }, [showModal]);

  useEffect(() => {
    let timer;
    if (uploadState.success) {
      timer = setTimeout(() => !showModalRef.current && dispatch(clearUploadBatch()), 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [dispatch, uploadState.success]);

  return (
    <>
      <UploadManagerToast
        bg={uploadState.success ? '#e6f3e4' : 'grey20'}
        linkColor={uploadState.success ? 'brand' : 'brandSubtle'}
        p={3}
        isOpen={showBar}
        onClick={() => {
          setShowModal(true);
          setShowBar(false);
          // stop timed clear queue
        }}
      >
        {uploadState.success && (
          <Flex alignItems="center">
            <Box as={FaCheck} mr={2} color="green" />
            <Text fontSize={3} color="grey20">
              {uploadMessage}
            </Text>
          </Flex>
        )}
        {uploadState.error && (
          <Flex alignItems="center">
            <Box as={FaExclamationTriangle} color="noticeIntense" mr={2} />
            <Text color="white" fontSize={3}>
              {uploadMessage}
            </Text>
            <Text color="white" mx={2}>
              &bull;
            </Text>
            <Text color="noticeIntense">
              {error} {pluralize('Error', error)}
            </Text>
          </Flex>
        )}
        {uploadState.uploading && (
          <Flex alignItems="center">
            <Box
              as={FaSyncAlt}
              mr={2}
              color="white"
              css={`
                animation: ${rotate} 2s linear infinite;
              `}
            />
            <Text color="white" fontSize={3}>
              {uploadMessage}
            </Text>
          </Flex>
        )}
      </UploadManagerToast>
      {showModal && <UploadManagerModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default UploadManager;
