import styled from '@emotion/styled';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import { flexbox, color, space, layout } from 'styled-system';

const BannerOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  ${flexbox};
  ${layout};
  ${color};
  ${space};
`;

const Banner = ({ title, subtitle, linkLabel, linkURL, onClose, ...props }) => (
  <BannerOuter {...props}>
    <Flex alignItems="center" m="auto" flex="1" css="max-width: 800px">
      <Box mr="auto" flex="1">
        <Heading fontSize={2} pb={1} fontFamily="sans">
          {title}
        </Heading>
        <Text fontSize={0} color="mutedText">
          {subtitle}
        </Text>
      </Box>
      <Flex flex="0 1" flexDirection="column" alignItems="center">
        <Button
          as="a"
          rel="noreferrer noopenner"
          color="white"
          bg="brand"
          fontSize={1}
          mb={1}
          mx="auto"
          type="button"
          href={linkURL}
          onClick={onClose}
        >
          {linkLabel}
        </Button>
        <Button
          onClick={onClose}
          bg="transparent"
          color="lightText"
          fontSize={0}
          border="none"
          mx="auto"
          px={0}
          py={0}
          fontWeight="normal"
          css="text-decoration: underline;"
        >
          Dismiss
        </Button>
      </Flex>
    </Flex>
  </BannerOuter>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Banner;
