import React from 'react';
import PropTypes from 'prop-types';
import { FaCog } from 'react-icons/fa';
import { ManageTags } from './styled';

const ManageTagsButton = ({ onClick }) => (
  <ManageTags onClick={onClick}>
    <FaCog style={{ marginRight: '4px' }} />
    Manage Tags
  </ManageTags>
);

ManageTagsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ManageTagsButton;
