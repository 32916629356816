import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { layout, color, border, flexbox, space } from 'styled-system';
import { Text, Box } from 'rebass';

import { useEntity } from '../../entities/hooks';
import MemberName from '../../members/name';
import MemberAvatar from '../../members/avatar';

const Pill = styled.div`
  display: flex;
  ${layout};
  ${color};
  ${border};
  ${flexbox};
  ${space};
`;

const SharedMember = ({ memberId, shareType, shareId, height, ...props }) => {
  const { entity } = useEntity({ type: shareType, id: shareId });
  // console.log(shareType, shareId);

  if (!memberId && !entity['with-contact']) return false;

  return (
    <Pill
      bg="grey90"
      height={height}
      borderRadius={height / 2}
      alignItems="center"
      fontWeight="bold"
      {...props}
    >
      <Box m={1}>
        <MemberAvatar id={memberId || entity['with-contact'].id} size={height - 8} />
      </Box>
      <Text as="span" pr={2} fontSize={1}>
        <MemberName id={memberId || entity['with-contact'].id} />
      </Text>
    </Pill>
  );
};
SharedMember.propTypes = {
  shareId: PropTypes.string.isRequired,
  height: PropTypes.number,
  shareType: PropTypes.string,
  memberId: PropTypes.string,
};
SharedMember.defaultProps = {
  height: 36,
  shareType: 'story-shares',
  memberId: '',
};

export default SharedMember;
