import React from 'react';
// import PropTypes from 'prop-types';
import Component from '@reach/component-component';
import { Formik } from 'formik';
import store from 'store';
import Modal from '../app/modal/v2';
import { PlainTitle } from '../app/modal/v2/styled';
import ActivateSubscriptionForm from './form';
import ActivateSuccess from './success';

const ActivateSubscriptionPopup = () => (
  <Component
    initialState={{ subscriptionCode: '', planName: '' }}
    didMount={({ setState }) => {
      const subscriptionCode = store.get('lt_subscription_code');
      if (subscriptionCode) { setState({ subscriptionCode }); }
    }}
  >
    {({ state: { subscriptionCode, planName }, setState }) => (
      subscriptionCode && (
        <Modal
          onClose={() => setState({ subscriptionCode: '' })}
          closeButton
        >
          {!planName && (
            <Modal.InnerWrap>
              <PlainTitle>Activate your Subscription</PlainTitle>
              <Formik
                initialValues={{ code: subscriptionCode }}
                onSubmit={(values) => {
                  setState({ planName: '5 year plan' });
                }}
                component={ActivateSubscriptionForm}
              />
            </Modal.InnerWrap>
          )}
          {planName && (
            <Modal.InnerWrap>
              <ActivateSuccess
                planName={planName}
                onClose={() => {
                  store.remove('lt_subscription_code');
                  setState({ subscriptionCode: '', planName: '' });
                }}
              />
            </Modal.InnerWrap>
          )}
        </Modal>
      )
    )}
  </Component>
);

ActivateSubscriptionPopup.propTypes = {
};

export default ActivateSubscriptionPopup;
