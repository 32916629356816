

import { Button } from 'rebass';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import Tippy from '@tippy.js/react';
// import { useHistory } from 'react-router-dom';

import { TooltipButton, TooltipWrap } from '../../../../stories/story-detail-header/styled';
import { useMatch, useHistory } from '../../../../app/hooks';
import EllipsisIcon from '../../../../assets/svg/ellipsis-icon';

const StoryRequestDetailOptions = () => {
  const { replace } = useHistory();
  const {
    params: { type, id, storyRequestId },
  } = useMatch();
  const deleteRoute = `/${type}/${id}/story-requests/${storyRequestId}/delete`;

  return (
    <Tippy
      content={
        <TooltipWrap>
          <TooltipButton destructive onClick={() => replace(deleteRoute)}>
            <FontAwesome name="trash" /> Delete
          </TooltipButton>
        </TooltipWrap>
      }
      interactive
      theme="light"
      arrow
      trigger="click"
      placement="bottom"
      zIndex="10000"
    >
      <Button variant="transparent" width={40} css="height: 40px;">
        <EllipsisIcon dark />
      </Button>
    </Tippy>
  );
};

export default StoryRequestDetailOptions;
