import React, { useEffect, useState } from 'react';
import { Button, Text, Flex } from 'rebass';

import { patchMemoir } from '../api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import OptionButton from '../../collections/new/gender/option-button';

const buttonProps = {
  bg: 'white',
  color: 'defaultText',
  mb: 3,
  type: 'button',
  fontFamily: 'quicksand',
  fontSize: 4,
  fontWeight: 'normal',
};

const MemoirSubjectStillWithUs = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: memoir } = useEntity({ ...params });
  const { receive } = useEntityActionCreators();
  const [value, setValue] = useState(null);
  const [submitting, setSubmitting] = useState(null);

  const { 'subject-alive': subjectAlive } = memoir;
  useEffect(() => {
    setValue(subjectAlive);
  }, [subjectAlive]);

  const handleSubmit = async () => {
    if (value !== subjectAlive) {
      setSubmitting(true);
      const body = {
        'subject-alive': value,
        'lock-version': memoir['lock-version'],
      };

      const memoirRes = await patchMemoir({ id: params.id, ...body });
      receive({ payload: memoirRes });
    }
    push(`/memoirs/${params.id}`);
  };

  return (
    <FullPageForm
      onBackClick={() => push(`/memoirs/new/${params.id}/date-of-birth`)}
      title={`Is ${memoir['subject-name']} still with us?`}
    >
      <Flex flexDirection="column" pt={5}>
        <OptionButton
          {...buttonProps}
          disabled={submitting}
          selected={value === true}
          onClick={() => {
            setValue(true);
          }}
        >
          Yes{' '}
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </OptionButton>
        <OptionButton
          {...buttonProps}
          disabled={submitting}
          selected={value === false}
          onClick={() => {
            setValue(false);
          }}
        >
          No{' '}
          <span role="img" aria-label="prayer hands">
            🙏
          </span>
        </OptionButton>
        <Text color="grey60" textAlign="center">
          (This information is used so we can give you more relevant prompts)
        </Text>
        <Button
          onClick={() => handleSubmit()}
          variant="primary"
          disabled={submitting || typeof value !== 'boolean'}
          mt={5}
          fontSize={20}
          style={{ padding: '16px' }}
        >
          Next
        </Button>
      </Flex>
    </FullPageForm>
  );
};

export default MemoirSubjectStillWithUs;
