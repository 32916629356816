import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { UAContext } from '@quentin-sommer/react-useragent';
import bellIcon from './assets/bell.svg';
import { subscribeUser } from './helper';
import { Wrap, InfoWrap, CtaWrap, Button } from './styled';

const PushPrompt = () => {
  // Notification.permission can be 'granted', 'default', or 'denied'
  const [subscriptionStatus, setSubscriptionStatus] = useState(Notification.permission);
  const [snoozeStatus, setSnoozeStatus] = useState('');
  const {
    uaResults: { safari },
  } = useContext(UAContext);

  useEffect(() => {
    const snoozeCookie = Cookies.get('snooze-notification-prompt');
    setSnoozeStatus(snoozeCookie);
  }, []);

  const handleSnoozeClick = () => {
    Cookies.set('snooze-notification-prompt', 'true', { expires: 7 });
    setSnoozeStatus('true');
  };

  const handleNotificationsClick = () => {
    subscribeUser();
    setSubscriptionStatus('granted');
  };

  const shouldRender = subscriptionStatus === 'default' && !snoozeStatus;

  if (!shouldRender) return false;

  if (safari) return false;

  if (!process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY) {
    return false;
  }

  return (
    <Wrap>
      <InfoWrap>
        <img src={bellIcon} alt="bell" />
        <p>Get notified when someone shares a story with you!</p>
      </InfoWrap>
      <CtaWrap>
        <Button onClick={handleNotificationsClick} primary>
          Turn On Notifications
        </Button>
        <Button onClick={handleSnoozeClick}>Maybe Later</Button>
      </CtaWrap>
    </Wrap>
  );
};

export default PushPrompt;
