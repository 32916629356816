

import { Card, Flex, Text } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import { useCurrentMemberId, useMemberAvatarSrc } from '../../../../members/hooks';
import { useEntity } from '../../../../entities/hooks';
import { useHistory, useMatch } from '../../../../app/hooks';
import { useVideoMediumFromStoryRequest } from '../hooks';
import MemberAvatar from '../../../../members/avatar';
import PlayButton from '../../../../story-requests/pending-requests/play-button';
import TimeAgo from '../../../../app/time-ago';

const StoryRequestListItem = ({ storyRequestShareParams }) => {
  const { entity: storyRequestShare } = useEntity(storyRequestShareParams);
  const { entity: storyRequest, isPresent } = useEntity(storyRequestShare['story-request']);
  const currentMemberId = useCurrentMemberId();
  const isSentRequest = currentMemberId !== storyRequestShare['with-contact'].id;

  const imageSrc = useMemberAvatarSrc(storyRequestShare.member);
  const { url } = useMatch();
  const { replace } = useHistory();
  const video = useVideoMediumFromStoryRequest(storyRequest.id);

  if (!isPresent) return false;

  return (
    <Card
      role="button"
      as={Flex}
      py={2}
      px={3}
      border="1px solid"
      borderColor="border"
      bg="white"
      onClick={() => replace(`${url}/${storyRequest.id}`)}
    >
      <Card
        backgroundImage={video ? '' : `url(${imageSrc})`}
        backgroundPosition="center"
        backgroundSize="cover"
        boxShadow="small"
        borderRadius={8}
        mr={2}
        css="
          display: flex;
          align-items: center;
          justify-content: center;
          width: 124px;
          height: 200px;
          position: relative;
        "
      >
        {video && (
          <ReactPlayer url={video.tmpUrl || video['asset-url']} width="100%" height="100%" />
        )}
        <PlayButton style={{ position: 'absolute' }} />
      </Card>
      <Flex flexDirection="column" alignItems="flex-start">
        <Text color="black">{storyRequest.title || 'Story Request'}</Text>
        <Text as="time" color="lightText" fontSize={0}>
          <TimeAgo date={storyRequest['created-at']} />
        </Text>
        {isSentRequest && (
          <MemberAvatar mt="auto" size={60} id={storyRequestShare['with-contact'].id} />
        )}
      </Flex>
    </Card>
  );
};

StoryRequestListItem.propTypes = {
  storyRequestShareParams: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default StoryRequestListItem;
