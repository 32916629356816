import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useUnreadSharedStoriesActivitiesCount } from './hooks';
import SidebarIconLink from './sidebar-icon-link';
import CircleIcon from '../../icons/circle-icon';
import AllStoriesIcon from './all-stories-icon';

const SharedWithMeLink = ({ onClick }) => {
  const { pathname } = useLocation();
  const sharedUnreadCount = useUnreadSharedStoriesActivitiesCount();
  return (
    <SidebarIconLink
      onClick={onClick}
      isSelected={pathname === '/shared-with-me'}
      label="Shared With Me"
      unreadStoryCount={sharedUnreadCount}
    >
      <CircleIcon size={40} bg="drawerSubtle" color="white">
        <AllStoriesIcon />
      </CircleIcon>
    </SidebarIconLink>
  );
};

SharedWithMeLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SharedWithMeLink;
