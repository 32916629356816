import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { trigger } from 'swr';

import NewMediaPopup from 'story-media/new';
import { postExternalMedium } from 'story-media/api';
import { useCurrentCollectionTag } from 'collections/hooks/index';
import { useNewStoryVisible, useCreateStory } from 'stories/hooks';
import { usePostNewMeeting } from 'chats/hooks';
import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { addStoryTag } from '../../api';
import { createMediaForFiles, createTextMedium } from '../../../story-media/actions';
import { toggleNewStoryVisible } from '../../actions';
import AnimatedWrap from '../../../app/modal/v2/animated-wrap';

const GlobalNewStory = () => {
  const newStoryVisible = useNewStoryVisible();
  const newStoryTag = useCurrentCollectionTag();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { currentCollection } = useContext(CurrentCollectionContext);
  const postNewMeeting = usePostNewMeeting();

  const submitText = (storyId, values) => dispatch(createTextMedium(storyId, values));
  const submitMedia = (storyId, files, position) =>
    dispatch(createMediaForFiles(storyId, files, position));
  const getStoryIdFromPayload = payload => get(payload, 'data.result.stories[0]', '');

  const createNewStory = useCreateStory();
  const closePopup = useCallback(() => {
    dispatch(toggleNewStoryVisible(false));
  }, [dispatch]);

  return (
    <AnimatedWrap on={newStoryVisible} posedKey="story-global">
      {newStoryVisible && (
        <NewMediaPopup
          title="Start a Story"
          onRecordChat={async () => {
            const [payload, newStoryId] = await postNewMeeting();
            push(`/stories/new/${newStoryId}/chats/${payload.data.result.meetings[0]}`);
            closePopup();
          }}
          onSubmitText={({ text }) => {
            let storyId;
            return createNewStory()
              .then(async ({ payload }) => {
                storyId = getStoryIdFromPayload(payload);
                if (!storyId) return false;
                if (newStoryTag) {
                  await addStoryTag({
                    journalId: currentCollection.id,
                    storyId,
                    name: newStoryTag,
                  });
                }

                return submitText(storyId, { text, position: 0 });
              })
              .then(() => {
                trigger('/v3/my/chronicle_collection');
                closePopup();
                push(`/stories/new/${storyId}`);
              });
          }}
          onSubmitMedia={files => {
            let storyId;
            return createNewStory()
              .then(async ({ payload }) => {
                storyId = getStoryIdFromPayload(payload);
                if (!storyId) return false;
                if (newStoryTag) {
                  await addStoryTag({
                    journalId: currentCollection.id,
                    storyId,
                    name: newStoryTag,
                  });
                }

                return submitMedia(storyId, files, 0);
              })
              .then(() => {
                trigger('/v3/my/chronicle_collection');
                closePopup();
                push(`/stories/new/${storyId}`);
              });
          }}
          onSubmitExternalMedium={({ externalURL }) => {
            let storyId;
            return createNewStory()
              .then(async ({ payload }) => {
                storyId = getStoryIdFromPayload(payload);
                if (!storyId) return false;
                if (newStoryTag) {
                  await addStoryTag({
                    journalId: currentCollection.id,
                    storyId,
                    name: newStoryTag,
                  });
                }

                return postExternalMedium({ externalURL, storyId, position: 0 });
              })
              .then(() => {
                trigger('/v3/my/chronicle_collection');
                closePopup();
                push(`/stories/new/${storyId}`);
              });
          }}
          onClose={closePopup}
        />
      )}
    </AnimatedWrap>
  );
};

export default GlobalNewStory;
