import { useMemo } from 'react';
import truncate from 'truncate';
import { useStripHTML } from 'app/hooks/index';

export const useCaptionText = (text = '', truncated = false, truncateLength = 75) => {
  const strippedText = useStripHTML(text);
  return useMemo(() => {
    if (!strippedText) return '';
    if (!truncated) return text;
    if (strippedText.length < truncateLength) return text;
    return `${truncate(strippedText, truncateLength)}...`;
  }, [strippedText, text, truncateLength, truncated]);
};

export default () => {};
