import { Box, Flex, Button, Text } from 'rebass';
import { get } from 'lodash';
import { space } from 'styled-system';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import normalize from 'jsonapi-normalizer';

import { useResetCollectionTag, useHowDoICollection } from 'collections/hooks';
import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { CurrentModalContext } from '../../modal/context';
import { colours } from '../../styles/theme';
import { useChronicleCollectionSWR } from '../../../collections/api';
import Loading from '../../loading/index';
import PlusIcon from '../../../assets/svg/plus-icon';
import SidebarCollectionLink from './collection-link';
import SharedWithMeLink from './shared-with-me-link';
import MyStoriesLink from './my-stories-link';
import HowDoILink from './how-do-i-link';
import { useHowDoICollectionPath } from './hooks';

const NavigationSidebar = ({ onCollectionClick }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { setCurrentCollection } = useContext(CurrentCollectionContext);
  const { setCurrentModal } = useContext(CurrentModalContext);
  const resetCollectionTag = useResetCollectionTag();

  const { data, error } = useChronicleCollectionSWR();

  const loading = !data && !error;

  const shares = useMemo(() => {
    return get(data, 'data.data.relationships["journal-shares"].data', []);
  }, [data]);

  const normalizedPayload = useMemo(() => (data ? normalize(data.data) : {}), [data]);

  useEffect(() => {
    if (pathname === ('/my-stories' || '/shared-with-me')) {
      setCurrentCollection(null);
    }
  }, [pathname, setCurrentCollection]);

  const { entity, isPresent } = useHowDoICollection();
  const howDoIPath = useHowDoICollectionPath();

  const goTo = (path = '') => {
    onCollectionClick();
    resetCollectionTag();
    setCurrentCollection(null);
    push(path);
  };

  return (
    <Wrap py={3}>
      <SharedWithMeLink onClick={() => goTo('/shared-with-me')} />
      <MyStoriesLink onClick={() => goTo('/my-stories')} />
      {isPresent && (
        <HowDoILink
          onClick={() => {
            goTo(howDoIPath);
          }}
          label={entity.name}
        />
      )}
      <Flex alignItems="center" px={3} css="min-height: 40px;">
        <Text color="drawerBasicallyWhite" fontWeight="bold" fontFamily="sans" fontSize={2}>
          Collections
        </Text>
        <Button
          variant="transparent"
          onClick={() => {
            onCollectionClick();
            setCurrentModal('new-collection');
            resetCollectionTag();
          }}
          ml="auto"
          css="padding-right: 0;"
        >
          <PlusIcon size="22px" colour={colours.drawerSubtle} />
        </Button>
      </Flex>
      <Box css="overflow-y: scroll;">
        {loading && <Loading local />}
        {shares.map(share => {
          // we show how do i collection link elsewhere
          if (share.type.includes('how-do-i')) return false;

          const shareEntity = normalizedPayload.entities[share.type][share.id];
          const journalEntity =
            normalizedPayload.entities[shareEntity.journal.type][shareEntity.journal.id];

          return (
            <SidebarCollectionLink
              onClick={() => {
                onCollectionClick();
                resetCollectionTag();
              }}
              id={share.id}
              type={share.type}
              key={share.id}
              storyCount={journalEntity['story-count'] || 0}
              membersCount={journalEntity['member-count'] || 0}
            />
          );
        })}
      </Box>
    </Wrap>
  );
};
NavigationSidebar.propTypes = {
  onCollectionClick: PropTypes.func,
};
NavigationSidebar.defaultProps = {
  onCollectionClick: () => {},
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  background-color: ${props => props.theme.colours.drawerBg};
  ${space};
`;

export default NavigationSidebar;
