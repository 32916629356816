
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Heading, Text } from 'rebass';
import storyListEmptyImg from '../assets/blank-page.png';

const StoryListEmptyMessage = ({ isMyStories }) => {
  return (
    <Box pt={4} mx="auto">
      <Heading color="mutedText" textAlign="center" mb={4}>
        {isMyStories
          ? 'This is the folder for all the Stories you create.'
          : 'There are no new stories shared with you'}
      </Heading>
      {!isMyStories && (
        <Box css="max-width: 35em; text-align: center;" mb={4}>
          <Text fontSize={2} color="mutedText">
            Try adding some pictures already on your phone. Maybe even add some captions{' '}
            <span role="img" aria-label="smiling face">
              😀
            </span>
            &gt; I bet they’d make a great story!
          </Text>
        </Box>
      )}
      {isMyStories && (
        <Box fontSize={3} css="max-width: 35em; text-align: center;" mb={4}>
          <Text mb={1}>
            Try it now — it’s easy! Tap the + button to start. (new paragraph after this)
          </Text>
          <Text mb={1}>
            Use some pictures already on your phone or computer. Maybe even add some captions
            <span role="img" aria-label="smiling face">
              😀
            </span>
            (new paragraph after this)
          </Text>
          <Text>I bet they&rsquo;d make a great story! </Text>
        </Box>
      )}
      <Box mx="auto" css="text-align: center;">
        <img src={storyListEmptyImg} alt="" />
      </Box>
    </Box>
  );
};

StoryListEmptyMessage.propTypes = {
  isMyStories: PropTypes.bool.isRequired,
};

export default StoryListEmptyMessage;
