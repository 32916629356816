

import { FaImage } from 'react-icons/fa';
import { Image, Text } from 'rebass';
import PropTypes from 'prop-types';
import React, { useMemo, useContext } from 'react';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import DrawerIconLink from './sidebar-icon-link';
import { useEntity } from '../../../entities/hooks';
import { useRouteFromEntityParams } from '../../../collections/hooks';
import CircleIcon from '../../icons/circle-icon';
import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import {
  useUnreadCollectionIds,
  useCollectionUnreadStoryCount,
} from '../../../notifications/notification/helper';
import { useCollectionCoverImage } from '../../../collections/hooks/cover';
import { useCollectionTitle } from '../../../collections/shared/title';

const SidebarCollectionLink = ({ id, storyCount, membersCount, type, onClick }) => {
  const { push } = useHistory();
  const { currentCollection, setCurrentCollection } = useContext(CurrentCollectionContext);
  const { entity: share } = useEntity({ id, type });
  const parentType = useMemo(() => type.replace('-shares', ''), [type]);
  const collectionParams = share.journal || share[parentType];
  const { entity: collection, isPresent } = useEntity(collectionParams);
  const imgSrc = useCollectionCoverImage({ size: 7, ...collectionParams });
  const collectionRoute = useRouteFromEntityParams(collectionParams);
  const unreadCollectionIds = useUnreadCollectionIds();
  const hasBadge = unreadCollectionIds.includes(collection.id);
  const unreadStoryCount = useCollectionUnreadStoryCount(collection.id);
  const title = useCollectionTitle(collectionParams);

  const collectionType = type.replace('-shares', 's');
  const isSelected = collection.id === ((currentCollection && currentCollection.id) || false);

  const sublabel = useMemo(
    () => `${pluralize('stories', storyCount, true)} - ${pluralize('members', membersCount, true)}`,
    [membersCount, storyCount]
  );

  if (!isPresent) return false;

  return (
    <DrawerIconLink
      onClick={() => {
        onClick();
        setCurrentCollection({ type: collectionType, id: collection.id });
        push(collectionRoute);
      }}
      label={title}
      sublabel={sublabel}
      isSelected={isSelected}
      hasBadge={hasBadge}
      unreadStoryCount={unreadStoryCount}
      isSample={collection['is-a-sample']}
    >
      <CircleIcon
        size={40}
        border="2px solid"
        bg="drawerSubtle"
        borderColor="drawerBasicallyWhite"
        borderRadius="8px"
        minWidth="40px"
      >
        {imgSrc && <Image src={imgSrc} height={40} width={40} css="object-fit: cover;" />}
        {!imgSrc && <Text as={FaImage} size={26} color="drawerBasicallyWhite" />}
      </CircleIcon>
    </DrawerIconLink>
  );
};

SidebarCollectionLink.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  storyCount: PropTypes.number.isRequired,
};

export default SidebarCollectionLink;
