import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useEntity } from 'entities/hooks';
import { useCurrentMemberId } from 'members/hooks/index';
import Role from './member-role';

const ShareRole = ({ id, type, onClick }) => {
  const { entity: share } = useEntity({ id, type });
  const { entity: member } = useEntity(share['with-contact']);
  const [role, setRole] = useState(share['contact-role']);
  const currentMemberId = useCurrentMemberId();

  const isFromCollection = Object.keys(share).includes('shared-direct') && !share['shared-direct'];

  console.log(type);

  const getSubLabel = () => {
    if (role === 'owner') return 'Owner';
    if (type.includes('story')) return 'Co-Creator';
    if (role === 'collaborator') return 'Co-Owner';
    return '';
  };

  return (
    <Role
      memberId={member.id}
      role={role}
      setRole={nextRole => {
        if (nextRole === role) {
          setRole('');
        } else {
          setRole(nextRole);
        }
        onClick({ role: nextRole });
      }}
      fromCollection={isFromCollection}
      disabled={role === 'owner' || member.id === currentMemberId}
      subLabel={getSubLabel()}
      coOwnerIcon={type === 'story-shares' ? 'PENCIL' : 'USER_COG'}
    />
  );
};

ShareRole.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

ShareRole.defaultProps = {
  type: 'story-shares',
};

export default ShareRole;
