

import { Button, Flex } from 'rebass';
import { isEmpty } from 'lodash';
import { singularize } from 'inflected';
import React, { useMemo } from 'react';
import { FaUserPlus } from 'react-icons/fa';

import { useEntity } from '../../../../entities/hooks';
import { useHistory, useMatch } from '../../../../app/hooks';
import Modal from '../../../../app/modal/v2';
import StoryRequestForm from '../form';
import SupportCopy from './copy-wrap';

const StoryRequests = () => {
  const { replace } = useHistory();
  const {
    params: { type, id },
  } = useMatch();
  const { entity } = useEntity({ type, id });

  const shareType = useMemo(() => `${singularize(type)}-shares`, [type]);
  const hasParticipants = useMemo(() => entity[shareType] && entity[shareType].length > 1, [
    entity,
    shareType,
  ]);

  if (isEmpty(entity)) return false;

  return (
    <Modal closeButton onClose={() => replace(`/${type}/${id}`)} aboveTooltip>
      <Flex
        bg="bg"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={3}
        px={4}
        css="min-height: 400px"
      >
        {hasParticipants && (
          <StoryRequestForm
            supportCopy={{
              title: 'Story Requests',
              body:
                'Select who you want to request from, then you will be able to send them a video request!',
            }}
            canHideInvitees={false}
          />
        )}
        {!hasParticipants && (
          <SupportCopy
            title="Looks like you haven’t invited anyone yet."
            body="After they accept your invitation, you'll be able to request stories from them."
          >
            <Button
              type="button"
              onClick={() => replace(`/${type}/${id}/participants`)}
              variant="primary"
              fontSize={3}
              py={3}
              px={4}
              css="display: flex; align-items: center;"
            >
              <FaUserPlus style={{ marginRight: '8px' }} />
              Invite Your Family
            </Button>
          </SupportCopy>
        )}
      </Flex>
    </Modal>
  );
};

export default StoryRequests;
