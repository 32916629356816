
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Button, Box } from 'rebass';
import { isEmpty } from 'lodash';
import { useMatch } from '../../../app/hooks';
import { useEntity } from '../../../entities/hooks';
import Paywall from '../../../app/paywall';
import { useEntityFromRouteParams } from '../../../collections/hooks/index';

const Banner = ({ detail, buttonLabel, onClick, bg }) => (
  <Box bg={bg}>
    <Flex alignItems="center" p={2} mx="auto" css="min-height: 52px; max-width: 1200px;">
      <Text>{detail}</Text>
      {buttonLabel && (
        <Button variant="primary" ml="auto" onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
    </Flex>
  </Box>
);
Banner.propTypes = {
  detail: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  bg: PropTypes.string,
};
Banner.defaultProps = {
  onClick: () => {},
  buttonLabel: null,
  bg: 'border',
};

// TODO note that purchase flow won't work until stripe is implemented
const SubscriptionBanner = () => {
  const {
    params: { id, type },
  } = useMatch();
  const { isOwner, entity: collection } = useEntityFromRouteParams({ type, id });
  const [showPaywall, setShowPaywall] = useState(false);
  const { entity: paywall } = useEntity({ ...collection.paywall });

  const isExpiring = ['expiring', 'churning'].includes(paywall['subscription-state']);
  const bg = useMemo(() => (isExpiring ? 'accentSubtle' : 'border'), [isExpiring]);
  const buttonLabel = useMemo(() => (isExpiring ? 'Learn' : 'Subscribe'), [isExpiring]);

  if (showPaywall) return <Paywall onClose={() => setShowPaywall(false)} errorData={paywall} />;

  if (isEmpty(paywall)) return false;
  if (!['expiring', 'churning', 'expired', 'churned'].includes(paywall['subscription-state']))
    return false;

  return (
    <Banner
      bg={bg}
      detail={paywall.detail}
      buttonLabel={isOwner && buttonLabel}
      onClick={() => setShowPaywall(true)}
    />
  );
};

export default SubscriptionBanner;
