
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Heading, Text, Box, Button, Image } from 'rebass';
import { IoMdClose as CloseIcon } from 'react-icons/io';

import logo from './assets/app-store-logo.svg';
import { BannerOuter } from '../browser-versions/wrap';

const IosBanner = ({ hideBanner }) => (
  <BannerOuter height={80} alignItems="center" p={2} bg="white">
    <Button type="button" variant="unset" fontSize={3} onClick={hideBanner}>
      <Text as={CloseIcon} color="lightText" size={16} css="display: block;" />
    </Button>
    <Card
      borderRadius={8}
      borderColor="border"
      border="1px solid"
      css="overflow: hidden;"
      ml={1}
      mr={2}
    >
      <Image src={logo} alt="LifeTales logo" css="display: block;" />
    </Card>
    <Box mr="auto">
      <Heading fontSize={3} fontWeight="bold">
        LifeTales
      </Heading>
      <Text fontSize={1} color="lightText">
        Get it on the App Store
      </Text>
    </Box>
    <Button
      as="a"
      href="https://itunes.apple.com/app/lifetales/id1244902797"
      p={2}
      bg="white"
      color="brand"
      border="1px solid"
      borderColor="brandMuted"
      target="_blank"
      rel="noopener noreferrer"
      onClick={hideBanner}
      fontSize={1}
    >
      Download
    </Button>
  </BannerOuter>
);

IosBanner.propTypes = {
  hideBanner: PropTypes.func.isRequired,
};

export default IosBanner;
