

import { Button, Flex, Heading, Text } from 'rebass';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../../../app/modal/v2';

const DeleteCollectionModal = ({
  onClose,
  headerIcon,
  bodyTitle,
  bodyText,
  onDestroyClick,
  destroyText,
  onCancelClick,
  cancelText,
  disabled,
}) => (
  <Modal onClose={onClose} bg="white" maxWidth={382}>
    <Flex justifyContent="center" alignItems="center" bg="background" p={4} css="height: 120px">
      {headerIcon}
    </Flex>
    <Flex flexDirection="column" alignItems="center" p={3}>
      <Heading as="h3" mb={3} textAlign="center">
        {bodyTitle}
      </Heading>
      <Text mb={3} textAlign="center">
        {bodyText}
      </Text>
      <Button
        variant="destructive"
        onClick={onDestroyClick}
        type="button"
        flex="1"
        fontSize={3}
        disabled={disabled}
        mb={3}
        css="width: 100%"
      >
        {destroyText}
      </Button>
      <Button
        variant="mutedOutline"
        onClick={onCancelClick}
        type="button"
        flex="1"
        fontSize={3}
        disabled={disabled}
        css="width: 100%"
      >
        {cancelText}
      </Button>
    </Flex>
  </Modal>
);

DeleteCollectionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  headerIcon: PropTypes.node.isRequired,
  bodyTitle: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onDestroyClick: PropTypes.func.isRequired,
  destroyText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DeleteCollectionModal;
