import { UserAgent } from '@quentin-sommer/react-useragent';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { ReactComponent as Selfie } from './selfie.svg';
import bg from './selfie-bg.png';

const Wrap = styled.div`
  height: 160px;
  border: 1px solid #c4c4c4;
  border-radius: 12px;
  background: url(${bg}) center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${props =>
    props.blur &&
    css`
      filter: blur(3px);
    `};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.sanFrancisco};
  position: relative;
  color: ${props => props.theme.colours.brand};
  padding: 0 16px;
  margin-top: 5px;
  cursor: pointer;

  input {
    position: absolute;
    left: -9999em;
  }
`;

const Outer = styled.div`
  position: relative;
`;

const VideoMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${props => props.theme.colours.mutedText};
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    width: 70%;
  }
`;

const BigSelfieButton = ({ handleChange }) => (
  <UserAgent mobile>
    {isMobile => (
      <>
        <Outer>
          <Wrap blur={!isMobile}>
            <Selfie />
            <Label htmlFor="selfie_video">
              <input
                type="file"
                id="selfie_video"
                capture="user"
                accept="video/*;capture=camcorder"
                onChange={handleChange}
              />
              Record a Video Selfie
            </Label>
          </Wrap>
          {!isMobile && (
            <VideoMessage>
              <p>Selfie recording currently only available on mobile devices</p>
            </VideoMessage>
          )}
        </Outer>
      </>
    )}
  </UserAgent>
);

BigSelfieButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default BigSelfieButton;
