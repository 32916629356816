import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PlainTitle, Copy } from '../app/modal/v2/styled';
import Button from '../app/buttons';

const ActivateSuccess = ({ planName, onClose }) => (
  <Fragment>
    <PlainTitle>Your subscription has been activated</PlainTitle>
    <Copy>Your {planName} subscription has been added to your account.</Copy>
    <Copy>You'll have access to all the great Child Journal and Social Memoir features as soon as they launch</Copy>
    <Copy>In the meantime, continue to enjoy LifeTales Basic!</Copy>
    <div>
      <Button
        primary
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  </Fragment>
);

ActivateSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  planName: PropTypes.string.isRequired,
};

export default ActivateSuccess;
