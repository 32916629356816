import { Box, Button, Flex, Heading } from 'rebass';
import React, { useReducer, useEffect } from 'react';
import { trigger } from 'swr';

import { SkipLink } from '../form-styled';
import { getAllCollections, getCollection, getCollectionTags, patchCollectionTag } from '../../api';
import { getCreateCollectionTitle } from '../helper';
import { useMatch, useHistory } from '../../../app/hooks';
import { useQueryEntities } from '../../../entities/hooks';
import CollectionTags from '../../tags';
import FullPageForm from '../../../app/full-page-form';
import SmallText from '../small-text';
import TagButton from './button';

const getBackPath = ({ type, id }) =>
  type === 'child-journals' ? `/child-journals/new/${id}/gender` : `/${type}/new/${id}`;

const TagPicker = () => {
  const { params: collection } = useMatch();
  const { push } = useHistory();

  useEffect(() => {
    return () => trigger('/v3/my/chronicle_collection');
  }, []);

  useQueryEntities({
    queries: [
      getAllCollections,
      () => getCollectionTags(collection),
      () => getCollection(collection),
    ],
  });

  const [{ additions, subtractions }, setState] = useReducer((n, p) => ({ ...n, ...p }), {
    additions: [],
    subtractions: [],
  });

  const toggleAddition = tag => {
    const nextAdditions = additions.map(add => add.name).includes(tag.name)
      ? additions.filter(add => add.name !== tag.name)
      : [...additions, tag];
    setState({ additions: nextAdditions });
  };
  const toggleSubtraction = tag => {
    const nextSubtractions = subtractions.map(add => add.name).includes(tag.name)
      ? subtractions.filter(add => add.name !== tag.name)
      : [...subtractions, tag];
    setState({ subtractions: nextSubtractions });
  };

  return (
    <FullPageForm
      title={getCreateCollectionTitle(collection.type)}
      onBackClick={() => push(getBackPath(collection))}
    >
      <Flex flexDirection="column" alignItems="stretch">
        <SmallText mb={2}>Tags help you to organize your stories.</SmallText>
        <SmallText>
          Your favorite tags become a sliding row at the top of the collection so stories will be
          easy to group and find.
        </SmallText>
        <Heading textAlign="center" fontSize={24} fontWeight="normal" pt={40} pb={14}>
          Select Tags
        </Heading>
        <Box my="auto" pb={40}>
          <Flex flexWrap="wrap" justifyContent="center" pl={10}>
            <CollectionTags {...collection}>
              {({ tags }) =>
                (tags || []).map(tag => (
                  <TagButton
                    name={tag.name}
                    selected={
                      tag.favourite
                        ? !subtractions.map(s => s.name).includes(tag.name)
                        : additions.map(a => a.name).includes(tag.name)
                    }
                    onClick={() =>
                      tag.favourite
                        ? toggleSubtraction({ name: tag.name })
                        : toggleAddition({ name: tag.name })
                    }
                    key={`${collection.id}-${tag.id}`}
                    flex="2 3 max-content"
                    mr={10}
                    mb={10}
                  />
                ))
              }
            </CollectionTags>
          </Flex>
          <SmallText>
            You can always change them later, or add new ones special to your family.
          </SmallText>
        </Box>
        <Flex flexDirection="column" alignItems="stretch">
          <CollectionTags {...collection}>
            {({ tags }) => {
              const count =
                (tags || []).filter(tag => tag.favourite).length +
                additions.length -
                subtractions.length;
              return <SmallText>{`(${count} tags selected)`}</SmallText>;
            }}
          </CollectionTags>
          <Button
            variant="primary"
            fontSize={20}
            mt={10}
            py={16}
            disabled={!additions.length}
            onClick={() => {
              const addFaves = additions
                .map(tag => {
                  const attributes = { ...tag, favourite: true };
                  return patchCollectionTag({ attributes, ...collection });
                })
                .filter(el => !!el);
              const removeFaves = subtractions
                .map(tag => {
                  const attributes = { ...tag, favourite: false };
                  return patchCollectionTag({ attributes, ...collection });
                })
                .filter(el => !!el);

              return Promise.all([...addFaves, ...removeFaves]).then(() => {
                push(`/${collection.type}/${collection.id}`);
              });
            }}
          >
            Next
          </Button>
          <SkipLink to={`/${collection.type}/${collection.id}`}>Skip</SkipLink>
        </Flex>
      </Flex>
    </FullPageForm>
  );
};

export default TagPicker;
