import { useMemo, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUserStoryShareFromStory } from 'story-shares/hooks/index';
import { useUnreadActivities } from 'notifications/hooks';
import { CurrentCollectionContext } from 'collections/current-collection-context';
import { DateTime } from 'luxon';
import { createStory } from 'stories/actions';
import { useEntity } from 'entities/hooks';
import { useCreateCover } from '../../collections/hooks';

export { default as useStoryDate } from './date';
export { default as useStoryCover } from './cover';
export { default as useStoryImages } from './images';
export { default as useStoryWithJournalId } from './useStoryWithJournalId';

export const useCreateStoryCover = () => {
  const createCover = useCreateCover();
  return async (id, cover) => {
    const fileBlob = await fetch(cover).then(res => res.blob());
    fileBlob.name = 'story-cover.png';
    createCover({ id, type: 'stories', cover: fileBlob });
  };
};

export const useStoryFromStoryShare = share => {
  const { storyShare } = useEntity({ type: 'story-shares', ...share });
  return useEntity({ type: 'stories', ...(storyShare.story || {}) });
};

export const useNewStoryVisible = () => useSelector(state => state.story.newStoryVisible);

export const useIsStoryMuted = id => {
  const storyShare = useUserStoryShareFromStory(id);
  const { entity: share } = useEntity(storyShare);

  return !!share && share.notify !== true;
};

export const useIsStoryUnread = id => {
  const unreadActivities = useUnreadActivities();
  return useMemo(() => !!unreadActivities.find(activity => activity['story-id'] === id), [
    id,
    unreadActivities,
  ]);
};

export const useCreateStory = () => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const dispatch = useDispatch();

  return useCallback(() => {
    const chronicleAttrs = {};

    if (currentCollection && currentCollection.id) {
      chronicleAttrs['journal-id'] = currentCollection.id;
    }

    chronicleAttrs['began-on'] = DateTime.local();

    return dispatch(createStory(chronicleAttrs));
  }, [currentCollection, dispatch]);
};
