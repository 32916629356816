/* eslint-disable max-len */

import store from 'store';
import { Text, Button, Flex, Image, Box, Heading, Link } from 'rebass';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// import { Button } from './styled';
import { CurrentModalContext } from '../../app/modal/context';
import Modal from '../../app/modal/v3';
import { useNewCollectionPath } from './link';
import childBg from './assets/child.png';
import customBg from './assets/custom.png';
import memoirBg from './assets/memoir.png';

const NewCollectionItem = ({ title, body, bg, onClose, type, ...props }) => {
  const { push } = useHistory();
  const path = useNewCollectionPath(type);
  return (
    <Box
      sx={{ borderRadius: 8, border: '3px solid', borderColor: 'border' }}
      bg="white"
      css="overflow: hidden;"
      {...props}
    >
      <Flex alignItems="stretch">
        <Flex alignItems="stretch" flex={2}>
          <Image src={bg} css="object-fit: cover; object-position: left; " />
        </Flex>
        <Flex flexDirection="column" alignItems="stretch" flex={3} p={3}>
          <Heading color="mutedText" fontSize={3} mb={2}>
            {title}
          </Heading>
          <Text fontSize={2} color="mutedText">
            {body}
          </Text>
          <Button
            variant="outline"
            type="button"
            mt={4}
            onClick={() => {
              onClose();
              push(path);
            }}
            fontWeight="bold"
          >
            Start Collection
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
NewCollectionItem.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  bg: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const NewCollection = () => {
  const { setCurrentModal } = useContext(CurrentModalContext);
  const onClose = () => setCurrentModal('');
  const nextFeatures = store.get('nextFeatures') || '';

  return (
    <Modal close={{ onClose }} title="Create a Collection">
      <Box p={3}>
        <NewCollectionItem
          title="Standard Collection"
          body="Family sharing? Hobby group? Office? Great for organizing your stories. Optionally share it with select friends so they can add stories too."
          bg={customBg}
          onClose={onClose}
          type="other-journals"
          mb={3}
        />
        <NewCollectionItem
          title="A Journal for my child"
          body="In just 2 minutes every other week, create a story about your child. Whether it's a precious moment or just a slice of life, it'll be a treasure for you and your child to look back on one day!"
          bg={childBg}
          mb={3}
          onClose={onClose}
          type="child-journals"
        />
        <NewCollectionItem
          title="Memoirs"
          body="Easily capture a life story, bit by bit, over time. Yours, your parents, a grandparent. It'll be an honor for them and a treasure for your kids!"
          bg={memoirBg}
          mb={3}
          onClose={onClose}
          type="memoirs"
        />
        {nextFeatures.includes('publicCollections') && (
          <Link color="brand" as={RouterLink} to="/personal-journals/new" onClick={() => onClose()}>
            New Public Collection
          </Link>
        )}
      </Box>
    </Modal>
  );
};

export default NewCollection;
