import PropTypes from 'prop-types';
import React from 'react';

import StoryListItem from './item';

const StoryListItemWithCollections = ({ id, type, ...props }) => {
  // const names = useSelector(state => selectStoryShareCollectionNames({ state, id, type }), isEqual);
  return <StoryListItem {...{ id, type }} {...props} />;
};

StoryListItemWithCollections.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default StoryListItemWithCollections;
