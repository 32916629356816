import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { DetailHeaderSpan } from './styled';
import { selectCurrentMember } from '../../members/selectors';
import { selectStory } from '../selectors';
import { selectStoryShareByMember } from '../../story-shares/selectors';
import { useHistory } from '../../app/hooks/index';

const ModifyLink = ({
  to,
  canModify,
  children,
  dispatch: _d,
  staticContext: _sc,
  onClick,
  ...props
}) => {
  const { replace } = useHistory();

  if (!canModify) return false;

  const handleClick = () => {
    if (to) {
      replace(to);
    }
    onClick();
  };

  return (
    <button onClick={handleClick} type="button">
      <DetailHeaderSpan {...props}>{children}</DetailHeaderSpan>
    </button>
  );
};

ModifyLink.propTypes = {
  to: PropTypes.string,
  canModify: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  destructive: PropTypes.bool,
  fixed: PropTypes.bool,
  confirm: PropTypes.bool,
  onClick: PropTypes.func,
};

ModifyLink.defaultProps = {
  to: '',
  onClick: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const { id: storyId } = ownProps.match.params;
  const { id: memberId } = selectCurrentMember(state);
  const story = selectStory(state, storyId);

  const isOwner = (story.member && story.member.id) === memberId;
  const memberStoryShare = selectStoryShareByMember(state, memberId, story.id);
  const isCollaborator = !!(
    memberStoryShare && memberStoryShare['contact-role'] === 'collaborator'
  );

  const canModify = isOwner || isCollaborator;
  return { canModify };
};

export default withRouter(connect(mapStateToProps)(ModifyLink));
