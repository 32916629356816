import { singularize, underscore } from 'inflected';
import { useMemo } from 'react';

const useCollectionTypeName = (type = '') =>
  useMemo(() => {
    switch (type) {
      case 'child-journals':
        return 'Child Journal';
      case 'family-journals':
        return 'Family Collection';
      case 'personal-journals':
        return 'Personal Journal';
      case 'memoirs':
        return 'Memoir';
      case 'other-journals':
      case 'custom':
        return 'Collection';
      default:
        return singularize(underscore(type));
    }
  }, [type]);

export default useCollectionTypeName;
