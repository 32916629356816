import React from 'react';
import { withRouter } from 'react-router-dom';
import Modal from '../../../app/modal/v2';
import CollectionOnboardCarousel from '../carousel';
import {
  SlideOne, SlideTwo, SlideThree, SlideFour,
} from './slides';
import historyShape from '../../../app/shapes/history';

const MyStoriesOnboard = ({ history: { push } }) => (
  <Modal
    fullscreenMobile
    closeButton
    onClose={() => push('/collections/new')}
  >
    <CollectionOnboardCarousel
      ctaText="Start a Personal Collection"
      onClose={() => push('/personal-journals/new')}
    >
      <SlideOne />
      <SlideTwo />
      <SlideThree />
      <SlideFour />
    </CollectionOnboardCarousel>
  </Modal>
);

MyStoriesOnboard.propTypes = {
  history: historyShape.isRequired,
};

export default withRouter(MyStoriesOnboard);
