import { useSelector } from 'react-redux';
import { IoIosArrowForward } from 'react-icons/io';
import pluralize from 'pluralize';
import { Heading, Text, Flex, Button } from 'rebass';
import { FaInfo, FaUserPlus } from 'react-icons/fa';
import React, { useCallback, useContext, useState } from 'react';

import CircleIcon from 'app/icons/circle-icon';
import { useEntity, useEntityActionCreators } from 'entities/hooks';
import { useMatch, useHistory } from 'app/hooks';
import { ShareRow } from 'app/share-row';
import Modal from 'app/modal/v3';
import { useParamsFromRoute, useRouteFromEntityParams } from 'collections/hooks';
import { ShareRowPublicCollectionLink } from 'stories/options/share-row-public-link';
import { CurrentCollectionContext } from 'collections/current-collection-context';
import SharedMember from 'stories/options/shared-member';
import { colours } from 'app/styles/theme';
import ParticipantsForm from './form';
import CollectionShareInfo from './collection-share-info';
import {
  patchCollectionShare,
  getCollection,
  destroyCollectionShare,
  postCollectionShare,
} from '../../api';

const CollectionParticipants = () => {
  const { params } = useMatch();
  const { replace } = useHistory();
  const { currentCollection } = useContext(CurrentCollectionContext);
  const { type, id } = useParamsFromRoute(params);
  const { entity } = useEntity({ type, id });
  const [showShareInfo, setShowShareInfo] = useState(false);
  const [showParticipantsForm, setShowParticipantsForm] = useState(false);
  const { update, receive } = useEntityActionCreators();

  // const parentType = useMemo(() => singularize(currentCollection.type), [currentCollection.type]);

  // float selected ones to the top
  const initialShares =
    useSelector(state => {
      return (entity['journal-shares'] || [])
        .map(share => {
          const shareType = pluralize(share.type);
          if (state.entities[shareType]) {
            return { ...state.entities[shareType][share.id], type: shareType };
          }
          return {};
        })
        .sort((a, b) => (a['contact-role'] && a['contact-role'] === 'owner' ? -1 : 0));
    }) || [];

  const backRoute = useRouteFromEntityParams({ type, id });
  const onClose = useCallback(() => replace(backRoute), [backRoute, replace]);

  if (showShareInfo) return <CollectionShareInfo onBack={() => setShowShareInfo(false)} />;
  if (showParticipantsForm) {
    // if (true) {
    return (
      <ParticipantsForm
        onBack={() => setShowParticipantsForm(false)}
        id={id}
        type={currentCollection.type}
        initialShares={initialShares}
        updateCollectionShares={async changes => {
          await Promise.all(
            Object.keys(changes.additions).map(memberId =>
              postCollectionShare({
                id,
                type,
                attributes: {
                  'with-contact-id': memberId,
                  'contact-role': changes.additions[memberId],
                },
              })
            )
          );

          await Promise.all(
            Object.keys(changes.updates).map(shareId => {
              const share = initialShares.find(el => el.id === shareId);
              return patchCollectionShare({
                shareId,
                id,
                type,
                attributes: {
                  'with-contact-id': share['with-contact'].id,
                  'contact-role': changes.updates[shareId],
                },
              });
            })
          );

          await Promise.all(
            changes.deletions.map(shareId => destroyCollectionShare({ id, type, shareId }))
          );

          update({
            entityType: type,
            id,
            attrs: {
              'journal-shares': entity['journal-shares'].filter(
                share => !changes.deletions.includes(share.id)
              ),
            },
          });

          const payload = await getCollection({ id, type });
          receive({ payload });
          setShowParticipantsForm(false);
        }}
      />
    );
  }

  return (
    <Modal close={{ onClose }} title="Invite Participants">
      <ShareRow
        renderIcon={() => (
          <CircleIcon bg="accent400">
            <FaInfo />
          </CircleIcon>
        )}
        label="Learn More About Sharing a Collection"
        onClick={() => setShowShareInfo(true)}
        bg="accentSubtle"
        css="cursor: pointer;"
      >
        <Text as={IoIosArrowForward} pl={1} color="mutedText" />
      </ShareRow>
      <ShareRow
        renderIcon={() => (
          <Flex
            css="height: 36px; width: 36px; border-radius: 50%; font-size: 19px;"
            justifyContent="center"
            alignItems="center"
            bg="#E4DFFF"
          >
            <FaUserPlus color={colours.brand} />
          </Flex>
        )}
        label="Invite your LifeTales Friends"
        onClick={() => setShowParticipantsForm(true)}
      >
        <Text css="display: inline-flex; align-items: center;">
          {pluralize('people', entity['member-count'], true)}
          <Text as={IoIosArrowForward} pl={1} color="mutedText" />
        </Text>
      </ShareRow>
      <ShareRowPublicCollectionLink id={currentCollection.id} type={currentCollection.type} />
      <Heading fontSize={2} p={2}>
        Participants:
      </Heading>
      <ShareRow css="border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;overflow:hidden;">
        <Button
          variant="unset"
          onClick={() => setShowParticipantsForm(true)}
          css="display: flex; align-items: center; width: 100%"
        >
          <Flex as="span" mr="auto" flexWrap="wrap" mb={-2}>
            {initialShares.map(share => (
              <SharedMember
                memberId={share['with-contact'] && share['with-contact'].id}
                shareId={share.id}
                mx={1}
                mb={2}
                key={share.id}
              />
            ))}
          </Flex>
          <Text color="mutedText" css="display: flex; align-items: center;">
            <Text as={IoIosArrowForward} pl={1} color="mutedText" />
          </Text>
        </Button>
      </ShareRow>
    </Modal>
  );
};

export default CollectionParticipants;
