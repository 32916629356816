import { Route, Switch } from 'react-router-dom';
import React from 'react';

import MemoirDate from './date';
import MemoirName from './memoir-name';
import MemoirRelationship from './relationship';
import MemoirSubjectName from './subject-name';
import MemoirSubjectIsWithUs from './is-with-us';

const NewMemoir = () => (
  <Switch>
    <Route path="/:type/new/:id/date-of-birth" component={MemoirDate} />
    <Route path="/:type/new/:id/subject-name" component={MemoirSubjectName} />
    <Route path="/:type/new/:id/memoir-name" component={MemoirName} />
    <Route path="/:type/new/:id/is-with-us" component={MemoirSubjectIsWithUs} />
    <Route path="/:type/new/:id?" component={MemoirRelationship} />
  </Switch>
);

export default NewMemoir;
