import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import styled from '@emotion/styled';
import TextField from '../app/form/formik/text';
import Button from '../app/buttons';

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
`;

const ActivateSubscriptionForm = ({ isSubmitting }) => (
  <StyledForm>
    <Field
      name="code"
      label="Activation Code"
      showError
      placeholder="Enter your Activation Code"
      component={TextField}
    />
    <Button disabled={isSubmitting} loading={isSubmitting} primary type="submit">
      Confirm and Activate
    </Button>
  </StyledForm>
);

ActivateSubscriptionForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default ActivateSubscriptionForm;
