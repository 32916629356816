import React from 'react';
import { Flex, Box } from 'rebass';

import { useIsNavigationVisible } from 'ui/hooks';
import { LinksWrapRight } from './styled';
import * as Links from '../links';
import wordmark from '../../logo/wordmark.svg';
import { useHasDrawer } from '../../mobile-drawer/hooks';
import AddButton from '../add-button';

const Header = () => {
  const hasDrawer = useHasDrawer();
  const isVisible = useIsNavigationVisible();
  if (hasDrawer || !isVisible) return false;

  return (
    <Box
      bg="white"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'border',
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.15)',
      }}
      css="z-index: 6; position: fixed; top: 0; left: 0; right: 0;"
    >
      <Flex px={3} alignItems="center" css="height: 70px;">
        <Box py={1}>
          <img src={wordmark} alt="" />
        </Box>
        <LinksWrapRight>
          <AddButton tippyOpts={{ position: 'bottom' }} showLabel />
          <Links.Chronicles showLabel />
          <Links.Notifications showLabel />
          <Links.Me showLabel />
        </LinksWrapRight>
      </Flex>
    </Box>
  );
};

export default Header;
