import 'react-datepicker/dist/react-datepicker.css';

import { DateTime } from 'luxon';
import { getIn } from 'formik';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { TextFieldWrap, Label, ErrorMessageWrap } from '../text/styled';
import calendarSvg from './calendar.svg';

const DateField = ({
  field,
  form,
  placeholder,
  label,
  showError,
  minDate,
  maxDate,
  pickerProps,
  ...props
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current.input) {
      ref.current.input.readOnly = true; // eslint-disable-line no-param-reassign
    }
  }, [ref]);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const dateFormat = 'MMMM d, yyyy';

  return (
    <TextFieldWrap error={error && touched} {...props}>
      {label && <Label {...field}>{label}</Label>}
      <DatePicker
        customInput={
          <input
            style={{
              background: `url(${calendarSvg}) no-repeat 97%`,
              backgroundColor: 'white',
              width: '100%',
            }}
          />
        }
        placeholderText={placeholder}
        dateFormat={dateFormat}
        selected={(field.value && new Date(field.value)) || null}
        onChange={e => form.setFieldValue(field.name, DateTime.fromJSDate(e).toISO())}
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="bottom-start"
        {...pickerProps}
        ref={ref}
      />
      {error && touched && <ErrorMessageWrap {...field}>{error}</ErrorMessageWrap>}
    </TextFieldWrap>
  );
};

DateField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  pickerProps: PropTypes.shape({}),
};

DateField.defaultProps = {
  width: 320,
  borderRadius: 6,
  fontSize: 16,
  label: '',
  placeholder: '',
  showError: false,
  minDate: null,
  maxDate: null,
  pickerProps: {},
};

export default DateField;
