import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';

import { Box, Flex, Button, Text } from 'rebass';
import { useCreateCover, useParamsFromRoute } from '../../hooks';
import { useEntity } from '../../../entities/hooks';

import Modal from '../../../app/modal/v3';
import { AvatarImageOrPlaceholder } from '../header/avatar-image-or-placeholder';
import useCollectionCover from '../../hooks/cover';
import { triggerGetCollection } from '../../api';

const CollectionCover = () => {
  const { id, type } = useParams();
  const { replace } = useHistory();
  const entityParams = useParamsFromRoute({ type, id });
  const { entity: collection } = useEntity(entityParams);
  const createCover = useCreateCover();
  const editorRef = useRef();

  const verb = useMemo(() => (get(collection, "['subject-avatar'].id") ? 'Update' : 'Add'), [
    collection,
  ]);
  const onClose = useCallback(() => replace(`/${type}/${id}`), [type, id, replace]);
  const coverUrl = useCollectionCover(entityParams);
  const [file, setFile] = useState(null);
  const [scale, setScale] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const title = file ? 'Crop and zoom cover' : 'Cover Preview:';

  const submitImage = async () => {
    setSubmitting(true);
    const canvas = editorRef.current.getImage().toDataURL(); // create chronicle subject avatar
    const cover = await fetch(canvas).then(res => res.blob());
    if (cover instanceof Blob) {
      await createCover({ ...entityParams, cover });
    }
    setSubmitting(false);
    triggerGetCollection({ type, id });
    onClose();
  };
  const { getInputProps, getRootProps } = useDropzone({ onDrop: dropped => setFile(dropped[0]) });

  return (
    <Modal
      title={`${verb} Collection Cover`}
      close={{
        onClose,
      }}
    >
      <Flex p={3} flexDirection="column" alignItems="center">
        <Text fontSize={1} color="mutedText" mt={2}>
          {title}
        </Text>
        {!file && (
          <>
            <AvatarImageOrPlaceholder size={140} coverUrl={coverUrl} mr={0} my={2} />
            <Box style={{ alignSelf: 'stretch' }} {...getRootProps()}>
              <input {...getInputProps({ accept: 'image/*' })} />
              <Flex
                bg="background"
                sx={{ borderColor: 'border', border: '4px dashed', borderRadius: 8 }}
                alignItems="center"
                justifyContent="center"
                alignSelf="stretch"
                p={4}
                mt={3}
                color="lightText"
              >
                <Button variant="outline" fontSize={1}>
                  Add Image
                </Button>
              </Flex>
            </Box>
          </>
        )}
        {file && (
          <>
            <Box mb={2}>
              <AvatarEditor ref={editorRef} image={file} height={140} width={140} scale={scale} />
              <Flex alignItems="center" mt={2}>
                <Text fontSize={1} fontWeight="bold" color="lightText" pr={1}>
                  Zoom:
                </Text>
                <input
                  type="range"
                  onChange={e => setScale(Number(e.target.value))}
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                />
              </Flex>
            </Box>
            <Button onClick={submitImage} type="button" variant="primary" disabled={submitting}>
              Submit
            </Button>
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default CollectionCover;
