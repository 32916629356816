/* eslint-disable no-param-reassign */
import { produce } from 'immer';

import * as types from './types';

const initialState = {
  checkmark: {},
  navigationVisible: true,
};

export default produce((state, action) => {
  const { type } = action;
  switch (type) {
    case types.SET_CHECKMARK: {
      state.checkmark = action.payload;
      break;
    }
    case types.CLEAR_CHECKMARK: {
      state.checkmark = {};
      break;
    }
    case types.TOGGLE_NAVIGATION_VISIBILITY: {
      state.navigationVisible = !!action.visible;
      break;
    }
    default:
  }
}, initialState);
