import React from 'react';

const AllStoriesIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.05557 3.81762e-07C2.79762 -0.000213316 2.54764 0.0893167 2.34847 0.253239C2.14931 0.417162 2.01338 0.645265 1.96399 0.898438L0.0195433 10.8984C0.00621987 10.9685 -0.000320763 11.0398 1.20903e-05 11.1111V17.7778C1.20903e-05 18.9923 1.00772 20 2.22223 20H17.7778C18.9923 20 20 18.9923 20 17.7778V11.1111C20.0003 11.0398 19.9938 10.9685 19.9805 10.8984L18.036 0.898438C17.9866 0.645265 17.8507 0.417162 17.6516 0.253239C17.4524 0.0893167 17.2024 -0.000213342 16.9445 3.81762e-07H3.05557ZM3.97137 2.22222H16.0287L17.7561 11.1111H14.4445C13.9667 11.1111 13.542 11.4162 13.3898 11.8707L12.7279 13.8585C12.6112 14.2074 12.283 14.4444 11.9141 14.4444H8.08595C7.71706 14.4444 7.38881 14.2085 7.27215 13.8585L6.60808 11.8707C6.45697 11.4173 6.03335 11.1111 5.55557 11.1111H2.24394L3.97137 2.22222Z"
      fill="white"
    />
  </svg>
);

export default AllStoriesIcon;
