import { Box, Flex } from 'rebass';
import { UAContext } from '@quentin-sommer/react-useragent';
import {
  FaCamera as ImageIcon,
  FaMicrophone as AudioIcon,
  FaImages as LibraryIcon,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import React, { useState, useContext, useCallback } from 'react';

import { DottedDivider } from 'app/styled';
import RecordChatButton from 'chats/record-chat-button';
import BigSelfieButton from './big-selfie-button';

import { Button } from './styled';

import ButtonLabelWrap from './button-label-wrap';
import Modal from '../../app/modal/v3';
import NewStoryWithMediumButton from './medium-button';
import TextIcon from '../../app/icons/add-text';
import NewStoryMediumForm from './form';
import NewMediaPopupMoreButton from './more-button';
import NewMultipleMedia from './multiple-media/index';

const NewMediaPopup = ({
  onClose,
  title,
  onSubmitText,
  onSubmitMedia,
  onSubmitExternalMedium,
  dividerText,
  onRecordChat,
}) => {
  const [currentForm, setCurrentForm] = useState('');
  const [initialFiles, setInitialFiles] = useState(false);
  const maxWidth = currentForm ? 620 : 414;
  const {
    uaResults: { android },
  } = useContext(UAContext);

  const handleChange = useCallback(
    e => {
      if (android) return setInitialFiles(e.target.files);
      return onSubmitMedia(e.target.files);
    },
    [android, onSubmitMedia]
  );

  if (initialFiles)
    return (
      <NewMultipleMedia
        onClose={onClose}
        initialFiles={initialFiles}
        maxWidth={maxWidth}
        onSubmitMedia={files => onSubmitMedia(files)}
      />
    );

  const submitting = false;

  return (
    <Modal
      close={{ onClose }}
      maxWidth={maxWidth}
      title={title}
      closeOnOutsideClick={false}
      aboveTooltip
    >
      <Box style={{ background: 'white', display: 'grid' }}>
        <NewStoryMediumForm
          onSubmitText={onSubmitText}
          onSubmitExternalMedium={onSubmitExternalMedium}
          currentForm={currentForm}
          setCurrentForm={setCurrentForm}
        />
        {!currentForm && (
          <Box pt={0} p={3}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 2 }} mb={3}>
              <RecordChatButton onRecordChat={onRecordChat} />
              <BigSelfieButton handleChange={handleChange} />
            </Box>
            <DottedDivider>{dividerText}</DottedDivider>
            <Flex alignItems="center" justifyContent="center">
              <ButtonLabelWrap label="Text">
                <Button
                  onClick={() => {
                    setCurrentForm('textForm');
                  }}
                  disabled={submitting}
                >
                  <TextIcon />
                </Button>
              </ButtonLabelWrap>
              <NewStoryWithMediumButton
                type="any"
                disabled={submitting}
                handleChange={handleChange}
                label="Library"
              >
                <LibraryIcon />
              </NewStoryWithMediumButton>
              <NewStoryWithMediumButton
                type="any"
                disabled={submitting}
                handleChange={handleChange}
                label="Camera"
                capture
              >
                <ImageIcon />
              </NewStoryWithMediumButton>
              <NewStoryWithMediumButton
                type="audios"
                disabled={submitting}
                handleChange={handleChange}
                label="Audio"
                capture
                accept={android ? 'audio/*;capture=microphone' : null}
              >
                <AudioIcon />
              </NewStoryWithMediumButton>
              <NewMediaPopupMoreButton onLinkClick={setCurrentForm} disabled={submitting} />
            </Flex>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

NewMediaPopup.defaultProps = {
  title: 'Add Media',
  dividerText: 'other types',
  pagePosition: null,
};

NewMediaPopup.propTypes = {
  title: PropTypes.string,
  dividerText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onRecordChat: PropTypes.func.isRequired,
  onSubmitText: PropTypes.func.isRequired,
  onSubmitMedia: PropTypes.func.isRequired,
  onSubmitExternalMedium: PropTypes.func.isRequired,
  pagePosition: PropTypes.number,
};

export default NewMediaPopup;
