import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';

import { MdEdit, MdShare, MdAnnouncement, MdGroup } from 'react-icons/md';
import { IoIosArrowBack } from 'react-icons/io';
import { useEntity } from 'entities/hooks/index';
import { useCurrentCollectionRoute } from 'collections/hooks/index';
import { setCheckmark } from '../../ui/actions';
import { useMatch } from '../../app/hooks';
import Options from './options';
import DetailHeaderButton from '../../app/detail/button';
import DetailNavOuter from '../../app/detail/nav-outer';
import StoryPermission from '../permission/index';

const StoryDetailHeaderNav = () => {
  const {
    url,
    params: { id },
  } = useMatch();
  const { push, replace } = useHistory();

  const { isOwner } = useEntity({ type: 'stories', id });
  const route = useCurrentCollectionRoute();

  const handleEditClick = () => {
    return push(`/story/${id}/edit`);
  };

  const handleBackClick = () => {
    if (route) return replace(route);
    if (isOwner) return replace('/my-stories');
    return replace('/shared-with-me');
  };

  return (
    <DetailNavOuter>
      <DetailHeaderButton onClick={handleBackClick} icon={IoIosArrowBack} mr="auto" />
      <StoryPermission feature="START_MEETING" id={id}>
        <DetailHeaderButton
          onClick={() => replace(`/story/${id}/new-chat`)}
          label="Start Chat"
          mr={2}
          icon={MdGroup}
        />
      </StoryPermission>
      <StoryPermission id={id} feature="SEND_UPDATE_NOTIFICATION">
        <DetailHeaderButton
          onClick={() => replace(`/story/${id}/notify`)}
          label="Send Update Notification"
          mr={2}
          icon={MdAnnouncement}
        />
      </StoryPermission>
      <StoryPermission id={id} feature="EDIT_STORY">
        <DetailHeaderButton onClick={handleEditClick} icon={MdEdit} label="Edit" mr={2} />
      </StoryPermission>
      <StoryPermission id={id} feature="SHARE_STORY">
        <DetailHeaderButton
          onClick={() => replace(`${url}/shares`)}
          icon={MdShare}
          label="Share"
          mr={2}
        />
      </StoryPermission>
      <Options memberId storyId={id} />
    </DetailNavOuter>
  );
};

export default connect(null, { setCheckmark })(StoryDetailHeaderNav);
