import { DateTime } from 'luxon';

export const getUploadTicketParams = (id, requestorType, attribute, fileName) => ({
  upload_ticket: {
    'requestor-id': id,
    'requestor-type': requestorType,
    'requestor-attribute': attribute,
    filename: fileName,
  },
});

export const formatKeys = attrs =>
  Object.keys(attrs).reduce(
    (acc, key) => ({
      ...acc,
      [key.replace('-', '_')]: attrs[key],
    }),
    {}
  );

export const bodyWithLockVersion = (entity = {}, values = {}) => ({
  ...values,
  'lock-version': entity['lock-version'],
});

export const getJSDateFromISO = (date = '') => (date ? DateTime.fromISO(date).toJSDate() : null);
