import { Box, Heading } from 'rebass';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import PropTypes from 'prop-types';
import React from 'react';
import pick from 'lodash/pick';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { createTextMedium, updateTextMedium } from '../actions';
import { getStoryPages } from '../../story-pages/actions';
import { useEntity } from '../../entities/hooks';
import Form from './form';
import Modal from '../../app/modal/v3';

const AddText = ({ onClose, ...props }) => {
  const { id, mediumId } = useParams();
  const { replace } = useHistory();
  const { search, pathname } = useLocation();
  const getEditPath = () => {
    if (pathname.includes('new')) {
      return `/stories/new/${id}`;
    }
    return `/story/${id}/edit`;
  };
  const editPath = getEditPath();

  const { entity: text, isPresent } = useEntity({ type: 'texts', id: mediumId });

  if (!isPresent) return false;

  return (
    <Modal
      title={isPresent ? 'Edit Text' : 'Add To Story'}
      bg="white"
      close={{ onClose: () => replace(editPath) }}
      maxWidth={620}
    >
      <Formik
        initialValues={text}
        onSubmit={values => {
          if (!text.id) {
            const { pos } = parse(search);
            const body = { 'page-position': Number(pos), ...values };
            props
              .createTextMedium(id, body)
              .then(() => props.getStoryPages(id))
              .then(() => {
                onClose();
                replace(editPath);
              });
          }
          if (text.id) {
            const body = pick(values, 'text', 'id', 'lock-version');
            props
              .updateTextMedium(text.id, body)
              .then(() => props.getStoryPages(id))
              .then(() => {
                onClose();
                replace(editPath);
              });
          }
        }}
        render={formikProps => <Form {...formikProps} onCancel={() => replace(editPath)} />}
      />
    </Modal>
  );
};

AddText.propTypes = {
  createTextMedium: PropTypes.func.isRequired,
  updateTextMedium: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  getStoryPages: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

AddText.defaultProps = { onClose: () => {} };

export default connect(null, { createTextMedium, updateTextMedium, getStoryPages })(AddText);
