import { Formik } from 'formik';
import { isEmpty, omit } from 'lodash';
import { object, string } from 'yup';
import React from 'react';

import { patchCollection } from '../../api';
import { prepareAttrs } from '../../../utils/api';
import { useCreateCover } from '../../hooks';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import { useHistory, useJsDate, useMatch } from '../../../app/hooks';
import FormBody from './form';

const EditMemoir = () => {
  const {
    params: { id },
  } = useMatch();
  const { push } = useHistory();
  const type = 'memoirs';
  const { receive } = useEntityActionCreators();
  const { entity: collection } = useEntity({ type, id });
  const initialDate = useJsDate(collection['date-of-birth'] || collection['created-at']);
  const createCover = useCreateCover();

  if (isEmpty(collection)) return false;

  return (
    <Formik
      initialValues={{
        'name-noac': collection.name,
        'first-name-noac': collection['first-name'],
        'date-of-birth': initialDate,
        'subject-relation-noac': collection['subject-relation'],
        'subject-alive-noac': collection['subject-alive'],
        cover: '',
      }}
      onSubmit={async ({ cover, ...values }, { setSubmitting }) => {
        const attrs = prepareAttrs(values);
        if (cover instanceof Blob) {
          await createCover({ id, type, cover });
        }
        const payload = await patchCollection({
          type,
          id,
          attributes: { id: collection.id, ...attrs },
        });
        // omit cover-image from patch collection response
        const updatedMemoir = Object.values(payload.data.entities.memoirs)[0];
        payload.data.entities.memoirs[updatedMemoir.id] = omit(updatedMemoir, [
          'subject-avatar-id',
          'subject-avatar',
        ]);
        receive({ payload });
        setSubmitting(false);
        push(`/${type}/${id}`);
      }}
      isInitialValid
      validationSchema={object().shape({
        'first-name-noac': string().required('First Name is required'),
        'name-noac': string().required('Memoir Name is required'),
      })}
      render={formikProps => <FormBody {...formikProps} collection={collection} type={type} />}
    />
  );
};

export default EditMemoir;
