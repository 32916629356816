
import React, { useState, useContext } from 'react';
import { UAContext } from '@quentin-sommer/react-useragent';
import { Flex, Heading, Text, Button, Image } from 'rebass';
import store from 'store';
import Modal from 'app/modal/v3';
import askImg from './notification-ask.png';
import { subscribeUser } from '../push-prompt/helper';

const getRenderStatusFromNotification = notification => {
  switch (notification) {
    case 'granted':
    case 'denied':
    default:
      return false;
    case 'default':
      return true;
  }
};

const InitialAskModal = () => {
  const {
    uaResults: { safari },
  } = useContext(UAContext);

  const [shouldRender, setShouldRender] = useState(() => {
    if (store.get('notification_modal_dismissed')) return false;
    return getRenderStatusFromNotification(Notification.permission);
  });

  const handleDismissClick = () => {
    store.set('notification_modal_dismissed', true);
    setShouldRender(false);
  };

  const handleNotificationClick = () => {
    subscribeUser();
    setShouldRender(false);
  };

  if (!shouldRender) return false;

  if (safari) return false;

  return (
    <Modal>
      <Flex flexDirection="column" alignItems="center" p={3} bg="white">
        <Heading textAlign="center" mb={2}>
          Get notified when a friend shares a story with you!
        </Heading>
        <Text color="mutedText">(you can always turn this off later)</Text>
        <Image src={askImg} my={4} css="max-width: 450px;" />
        <Button onClick={handleNotificationClick} variant="primary" mb={2}>
          I want to be notified
        </Button>
        <Button
          onClick={handleDismissClick}
          variant="transparent"
          css="height: 36px;"
          color="mutedText"
        >
          Not now
        </Button>
      </Flex>
    </Modal>
  );
};

export default InitialAskModal;
