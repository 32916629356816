import { Flex, Image, Box, Button } from 'rebass';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import React, { useState, useRef } from 'react';
import * as loadImage from 'blueimp-load-image';

import { useRect } from '@reach/rect';
import { ReactComponent as CropIcon } from '../../new/cover/assets/icon-circle-crop.svg';
import { Label } from '../../../app/form/formik/text/styled';
import { useEntity } from '../../../entities/hooks';
import Absolute from '../../../app/layout/absolute';
import CoverEditor from '../../new/cover/editor';
import CoverPreview from '../../new/cover/preview';
import { jsonApiReferenceShape } from '../../../app/shapes/index';
import addImageSvg from '../../new/cover/thumbnails/assets/add-cover-photo.svg';

const CoverPicker = ({ field, form, initialCover }) => {
  const { entity: imageEntity, isPresent } = useEntity({ type: 'images', ...initialCover });
  const previewSrc =
    field.value || (isPresent && (imageEntity.tmpUrl || imageEntity['asset-url'])) || '';

  const [isCropping, setCropping] = useState(false);
  const [editingSrc, setEditingSrc] = useState(null);

  const ref = useRef();
  const rect = useRect(ref);

  const handleDrop = async dropped => {
    if (!dropped[0]) return false;

    const image = await new Promise(resolve => {
      loadImage(
        dropped[0],
        canvas => {
          canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/png',
            100
          );
        },
        {
          canvas: true,
          aspectRatio: 2 / 1,
        }
      );
    });

    if (typeof field.value === 'string') {
      URL.revokeObjectURL(field.value);
    }

    const url = URL.createObjectURL(image);
    setEditingSrc(url);
    return form.setFieldValue(field.name, url);
  };

  const handleCropButtonClick = async assetSrc => {
    if (assetSrc) {
      await fetch(assetSrc)
        .then(res => res.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setEditingSrc(url);
          form.setFieldValue(field.name, url);
        });
    }
    setCropping(true);
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: e => {
      setCropping(false);
      handleDrop(e);
    },
  });

  return (
    <Box ref={ref}>
      {field.label && <Label {...field}>{field.label}</Label>}
      <>
        <Box css="position: relative">
          {isCropping && (
            <CoverEditor
              src={editingSrc}
              acceptEdit={cover => {
                URL.revokeObjectURL(field.value);
                const coverUrl = URL.createObjectURL(cover);
                form.setFieldValue(field.name, coverUrl);
              }}
              exitEditor={() => {
                setCropping(false);
                setEditingSrc(null);
              }}
              editorWidth={rect && rect.width}
            />
          )}
          {!isCropping && (
            <>
              <CoverPreview previewSrc={previewSrc} handleDrop={handleDrop} />
              {!!field.value && (
                <>
                  <Absolute display="inline-flex" right={10} bottom={10}>
                    <Button
                      variant="unset"
                      type="button"
                      borderRadius="50%"
                      onClick={() => handleCropButtonClick(previewSrc)}
                    >
                      <CropIcon height={36} />
                    </Button>
                  </Absolute>
                </>
              )}
            </>
          )}
        </Box>
        <Flex {...getRootProps()} alignItems="center" my={1} sx={{ cursor: 'pointer' }}>
          <input {...getInputProps({ accept: 'image/*', multiple: false })} />
          <Image width={40} mr={2} src={addImageSvg} alt="select from gallery" />
          Select from photo library
        </Flex>
      </>
    </Box>
  );
};
CoverPicker.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  initialCover: PropTypes.oneOfType([jsonApiReferenceShape, PropTypes.shape({})]),
};

CoverPicker.defaultProps = {
  initialCover: {},
};

export default CoverPicker;
