import { Formik } from 'formik';
import { string, object } from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

import { patchChildJournal } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionGender from '../../collections/new/gender';
import * as shapes from '../../app/shapes';

const ChildJournalGender = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: childJournal } = useEntity({ ...params });
  const { receive } = useEntityActionCreators();
  const initialValues = {
    gender: childJournal.gender || null,
  };
  const validationSchema = object().shape({
    gender: string(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  return (
    <FullPageForm
      onBackClick={() => push(`/child-journals/new/${params.id}/date-of-birth`)}
      title="Create a Child Journal"
    >
      <Formik
        {...{ validationSchema, initialValues, isInitialValid }}
        onSubmit={async values => {
          const body = prepareAttrs({ ...values, 'lock-version': childJournal['lock-version'] });
          // this attribute is required because of the way that the iOS app set stuff up

          // POST child journal
          const cjRes = await patchChildJournal({ id: params.id, ...body });
          const [id] = cjRes.data.result['child-journals'];
          receive({ payload: cjRes });
          push(`/child-journals/new/${id}/tags`);
        }}
        render={props => (
          <NewCollectionGender
            label={`Is ${childJournal['first-name']} a `}
            skipPath={`/child-journals/new/${params.id}/tags`}
            {...props}
          />
        )}
      />
    </FullPageForm>
  );
};

ChildJournalGender.propTypes = {
  match: PropTypes.shape({
    params: shapes.jsonApiReferenceShape.isRequired,
  }).isRequired,
  history: shapes.historyShape.isRequired,
};

export default ChildJournalGender;
