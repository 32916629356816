import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useUnreadMyStoriesActivityCount } from './hooks';
import SidebarIconLink from './sidebar-icon-link';
import CircleIcon from '../../icons/circle-icon';
import MyStoriesIcon from './my-stories-icon';

const MyStoriesLink = ({ onClick }) => {
  const { pathname } = useLocation();
  const myStoriesUnreadCount = useUnreadMyStoriesActivityCount();
  return (
    <SidebarIconLink
      onClick={onClick}
      isSelected={pathname === '/my-stories'}
      label="My Stories"
      unreadStoryCount={myStoriesUnreadCount}
    >
      <CircleIcon size={40} bg="drawerSubtle" color="white">
        <MyStoriesIcon />
      </CircleIcon>
    </SidebarIconLink>
  );
};

MyStoriesLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MyStoriesLink;
