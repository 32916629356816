import React from 'react';
import CollectionOnboardSlide from '../slide';
import bg1 from './assets/bg-1.png';
import bg2 from '../assets/bg-2.png';
import bg3 from '../assets/bg-3.png';
import bg4 from '../assets/bg-4.png';
import img2 from './assets/img-2.png';
import img3 from './assets/img-3.png';
import img4 from './assets/img-4.png';

export const SlideOne = () => (
  <CollectionOnboardSlide
    bg={bg1}
    header="My Child"
    body="This Collection is usually just shared between you, your spouse and your child (when they’re older). What a great journal this will be!"
  />
);

export const SlideTwo = () => (
  <CollectionOnboardSlide
    bg={bg2}
    header="Capture All The Cute"
    body="Save their special, silly, wonderful stories in just minutes a week."
    image={img2}
    imgAltText="collections list"
  />
);

export const SlideThree = () => (
  <CollectionOnboardSlide
    bg={bg3}
    header="The Whole Story"
    body="Add the rest of the story. What happened before you snapped the photo or stopped the video?"
    image={img3}
    imgAltText="baby story"
  />
);

export const SlideFour = () => (
  <CollectionOnboardSlide
    bg={bg4}
    header="No More Hide n’ Seek"
    body="Easily sort, tag and organize all the funny things they say and do."
    image={img4}
    imgAltText="tags"
  />
);
