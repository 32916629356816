import { Flex } from 'rebass';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { CountIndicator } from '../item/styled';
import StoryFilterLink from '../stories-dropdown/link';
import { useLogout } from '../../../auth/logout';

const StyledCountIndicator = styled(CountIndicator)`
  top: 14px;
  left: 129px;
`;

const MeDropdown = ({ handleClick, indicatorCount }) => {
  const logout = useLogout();

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <StoryFilterLink path="/profile" handleClick={handleClick}>
        <FontAwesome name="user" /> My Profile
      </StoryFilterLink>
      <StoryFilterLink path="/friends/add" handleClick={handleClick}>
        <FontAwesome name="user-plus" /> Add Friend
      </StoryFilterLink>
      <StoryFilterLink path="/friends" handleClick={handleClick}>
        <FontAwesome name="users" /> My Friends
        {!!indicatorCount && <StyledCountIndicator>{indicatorCount}</StyledCountIndicator>}
      </StoryFilterLink>
      <StoryFilterLink
        path="/"
        handleClick={() => {
          handleClick();
          logout();
        }}
      >
        <FontAwesome name="sign-out" /> Log Out
      </StoryFilterLink>
    </Flex>
  );
};

MeDropdown.propTypes = {
  handleClick: PropTypes.func.isRequired,
  indicatorCount: PropTypes.number.isRequired,
};

export default MeDropdown;
