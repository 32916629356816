import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import store from 'store';

import { convertStoryRequestToStory } from '../../../../story-requests/actions';
import { createMediaForFiles, createTextMedium } from '../../../../story-media/actions';
import { useHistory, useMatch } from '../../../../app/hooks';
import NewMediaPopup from '../../../../story-media/new';

const StoryRequestResponse = () => {
  const {
    params: { storyRequestId },
    url,
  } = useMatch();
  const { replace, push } = useHistory();
  const dispatch = useDispatch();

  const convertRequestToStory = useCallback(
    () => dispatch(convertStoryRequestToStory(storyRequestId)),
    [dispatch, storyRequestId]
  );
  const submitText = (storyId, values) => dispatch(createTextMedium(storyId, values), []);
  const submitMedia = (storyId, files, position) =>
    dispatch(createMediaForFiles(storyId, files, position));

  const getStoryIdFromPayload = useCallback(payload => payload.data.result.stories[0], []);

  return (
    <NewMediaPopup
      title="Respond with Your Story"
      onSubmitText={({ text }) => {
        let storyId;
        return convertRequestToStory()
          .then(({ payload }) => {
            storyId = getStoryIdFromPayload(payload);
            return submitText(storyId, { text, position: 1 });
          })
          .then(() => {
            return replace(`/stories/new/${storyId}`);
          });
      }}
      onSubmitMedia={files => {
        let storyId;
        return convertRequestToStory()
          .then(({ payload }) => {
            storyId = getStoryIdFromPayload(payload);
            return submitMedia(storyId, files, 1);
          })
          .then(() => {
            return replace(`/stories/new/${storyId}`);
          });
      }}
      onClose={() => {
        push(
          url
            .split('/')
            .slice(0, -1)
            .join('/')
        );
        store.remove('requestResponse');
      }}
    />
  );
};

export default StoryRequestResponse;
