import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import { useEntity } from 'entities/hooks';
import { StoryPageContext } from 'story-pages/item/context';

const Player = ({ editing, id }) => {
  const { entity: medium } = useEntity({ id, type: 'audios' });
  const { currentMedia, setCurrentMedia } = useContext(StoryPageContext);

  return (
    <Flex justifyContent="center" p={3} pt={editing ? 5 : 3}>
      <ReactPlayer
        url={medium.tmpUrl || medium['asset-url']}
        controls
        height={40}
        onPlay={() => setCurrentMedia(id)}
        onPause={() => setCurrentMedia(null)}
        playing={currentMedia === id}
      />
    </Flex>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  editing: PropTypes.bool,
};

Player.defaultProps = { editing: false };

export default Player;
