

import { useHistory } from 'react-router-dom';
import React, { useContext } from 'react';

import { TagContext } from '../tag-context';
import { useGetCollectionSWR } from '../../api';
import { useRouteFromEntityParams } from '../../hooks';
import Modal from '../../../app/modal/v3';
import TagManagement from './index';
import jsonApiReferenceShape from '../../../app/shapes/json-api-reference-shape';

const CollectionTagManagement = ({ collection }) => {
  const { replace } = useHistory();
  const { setCurrent } = useContext(TagContext);
  const route = useRouteFromEntityParams(collection);
  const { revalidate } = useGetCollectionSWR({ id: collection.id, type: collection.type });

  return (
    <Modal
      close={{
        onClose: () => {
          revalidate();
          replace(route);
        },
      }}
      title="Collection Tags"
    >
      <TagManagement onClickTag={tag => setCurrent(tag)} collection={collection} />
    </Modal>
  );
};

CollectionTagManagement.propTypes = {
  collection: jsonApiReferenceShape.isRequired,
};

export default CollectionTagManagement;
