import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import { motion, useMotionValue } from 'framer-motion';
import { GoTriangleDown } from 'react-icons/go';

const clipLineStyle = {
  gridArea: '1 / 1 / 2 / 1',
  width: 34,
  zIndex: 3,
  display: 'grid',
  gridTemplateRows: 'max-content 1fr',
  justifyItems: 'center',
};

const InnerClipline = () => (
  <>
    <Text color="#FF2B00" fontSize={3} my="-6px" as={GoTriangleDown} />
    <Box sx={{ width: '2px', bg: '#FF2B00' }} />
  </>
);

const PlayingClipLine = ({ clipLinePosition, originalDuration, pixelsPerSecond, onUpdate }) => {
  // console.log(clipLinePosition, originalDuration);
  const x = Math.ceil(originalDuration * pixelsPerSecond);
  const duration = (x - clipLinePosition) / pixelsPerSecond;
  const ref = useRef(null);

  return (
    <motion.div
      ref={ref}
      style={clipLineStyle}
      initial={{ x: clipLinePosition }}
      animate={{ x }}
      transition={{ duration, ease: 'linear' }}
      onUpdate={onUpdate}
    >
      <InnerClipline />
    </motion.div>
  );
};

const ClipLine = ({
  isPlaying,
  pixelsPerSecond,
  originalDuration,
  onDrag,
  onDragEnd,
  onCliplineStop,
  clipLinePosition,
  setClipLinePosition,
  onApproachingRightEdge,
  visibleTrackRangeRight,
}) => {
  const localXRef = useRef(0);
  const x = useMotionValue(0);
  const trackWidth = originalDuration * pixelsPerSecond;
  const handlePlayingCliplineUpdate = useCallback(
    info => {
      localXRef.current = info.x;
      x.set(info.x);
      if (visibleTrackRangeRight - info.x < 50) {
        onApproachingRightEdge();
      }
    },
    [onApproachingRightEdge, visibleTrackRangeRight, x]
  );

  useEffect(() => {
    if (!isPlaying) {
      setClipLinePosition(localXRef.current);
    }
  }, [isPlaying, setClipLinePosition]);

  useEffect(() => {
    x.set(clipLinePosition);
  }, [clipLinePosition, x]);

  if (isPlaying)
    return (
      <PlayingClipLine
        clipLinePosition={clipLinePosition}
        originalDuration={originalDuration}
        pixelsPerSecond={pixelsPerSecond}
        onUpdate={handlePlayingCliplineUpdate}
        onAnimationEnd={info => onCliplineStop(info.x)}
      />
    );

  return (
    <motion.div
      drag="x"
      style={{
        ...clipLineStyle,
        x,
      }}
      onDrag={(_event, info) => {
        onDrag(info.point.x);
      }}
      onDragEnd={(_event, info) => {
        if (info.point.x <= 0) {
          return onDragEnd(1);
        }
        if (info.point.x > trackWidth) {
          return onDragEnd(trackWidth);
        }
        return onDragEnd(info.point.x);
      }}
      dragMomentum={false}
    >
      <InnerClipline />
    </motion.div>
  );
};

ClipLine.propTypes = {
  onDrag: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onApproachingRightEdge: PropTypes.func,
  visibleTrackRangeRight: PropTypes.number.isRequired,
};

ClipLine.defaultProps = {
  onApproachingRightEdge: () => {},
};

export default ClipLine;
