import { Box, Button, Flex } from 'rebass';
import { Formik, Field, Form } from 'formik';
import { isEmpty, omit } from 'lodash';
import { object, string } from 'yup';
import React from 'react';
import Rect from '@reach/rect';

import { patchCollection } from '../../api';
import { prepareAttrs } from '../../../utils/api';
import { useCreateCover } from '../../hooks';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import { useHistory, useMatch } from '../../../app/hooks';
import CoverField from '../../../app/form/formik/cover';
import TextField from '../../../app/form/formik/text';

const EditFamilyJournal = React.memo(() => {
  const {
    params: { id },
  } = useMatch();
  const { push } = useHistory();
  const { entity: familyJournal } = useEntity({ id, type: 'family-journals' });
  const { receive } = useEntityActionCreators();
  const createCover = useCreateCover();

  if (isEmpty(familyJournal)) return false;

  return (
    <Formik
      initialValues={{
        'name-noac': familyJournal.name,
        cover: '',
      }}
      onSubmit={async ({ cover, ...values }, { setSubmitting }) => {
        const attrs = prepareAttrs(values);
        if (cover instanceof Blob) {
          // create chronicle subject avatar
          await createCover({ id, type: 'family-journals', cover });
        }
        const payload = await patchCollection({ type: 'family-journals', id, attributes: attrs });
        // omit cover-image from patch collection response
        const updatedFamilyJournal = Object.values(payload.data.entities['family-journals'])[0];
        payload.data.entities['family-journals'][updatedFamilyJournal.id] = omit(
          updatedFamilyJournal,
          'cover-image'
        );
        receive({ payload });
        setSubmitting(false);
        push(`/family-journals/${id}`);
      }}
      validationSchema={object().shape({
        'name-noac': string().required('First Name is required'),
      })}
      render={({ isValid, isSubmitting }) => (
        <Rect>
          {({ ref, rect }) => (
            <div ref={ref}>
              <Form>
                <Box mb={3}>
                  <Field
                    name="cover"
                    component={CoverField}
                    currentCover={familyJournal['cover-image'] && familyJournal['cover-image'].id}
                    width={rect && rect.width}
                    hasConfirm={false}
                  />
                </Box>
                <Box mb={3}>
                  <Field
                    component={TextField}
                    name="name-noac"
                    label="Name"
                    showError
                    width="100%"
                  />
                </Box>
                <Flex mb={3}>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    flex="1"
                    py={12}
                  >
                    Save
                  </Button>
                </Flex>
              </Form>
            </div>
          )}
        </Rect>
      )}
    />
  );
});

export default EditFamilyJournal;
