import { Box, Card, Button, Flex, Text } from 'rebass';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useCallback } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { ContextImage } from './styled';
import { ScrollContext } from '../../contexts/scroll';
import {
  getNotificationLink,
  getNotificationButtonLabel,
  useCollectionFromStoryId,
} from './helper';
import { markSingleNotificationRead } from '../actions';
import { useEntity } from '../../entities/hooks';
import Avatar from '../../members/avatar';
import TimeAgo from '../../app/time-ago';
import { CurrentCollectionContext } from '../../collections/current-collection-context';

const Notification = ({ id, onClose, ...props }) => {
  const { push } = useHistory();
  const { setScrollItemId } = useContext(ScrollContext);
  const { setCurrentCollection } = useContext(CurrentCollectionContext);
  const { entity, currentMemberId } = useEntity({ type: 'activities', id });
  const collection = useCollectionFromStoryId(entity.story ? entity.story.id : null);
  const handleClick = useCallback(() => {
    const location = {};
    if (!entity.read) {
      props.markSingleNotificationRead(currentMemberId, entity.id);
    }
    if (entity.subject && entity.subject.type !== 'connections') {
      setScrollItemId(entity.subject.id);
    }
    if (entity.topic === 'connection_accepted') {
      onClose();
    }
    if (entity.topic === 'child_journal_share') {
      onClose();
      location.pathname = `/child-journals/${entity['chronicle-id']}`;
    }
    if (collection) {
      setCurrentCollection(collection);
    }
    if (!location.pathname) {
      location.pathname = getNotificationLink(entity);
    }
    if (location.pathname.includes('/story')) {
      location.state = { fromNotifications: true };
    }
    push(location);
  }, [
    collection,
    currentMemberId,
    entity,
    onClose,
    props,
    push,
    setCurrentCollection,
    setScrollItemId,
  ]);

  const label = useMemo(() => getNotificationButtonLabel(entity), [entity]);
  return (
    <Card borderBottom="1px solid" borderColor="border">
      <Flex bg={!entity.read ? 'accentSubtle' : 'white'} p={3}>
        <Button
          variant="unset"
          onClick={handleClick}
          mr="auto"
          // css="all: unset; cursor: pointer; background-color: transparent;"
        >
          <Flex alignItems="center">
            <Box pr={1}>
              <Avatar id={entity.author && entity.author.id} size={35} />
            </Box>
            <Box flex="1" px={2} css="text-align: left">
              <Text fontSize={1} color="defaultText" pr={1}>
                {entity.message}
              </Text>
              <Box>
                <Text as="time" color="lightText" fontSize={0} css="white-space: nowrap">
                  <TimeAgo date={entity['created-at']} />
                </Text>
              </Box>
            </Box>
          </Flex>
        </Button>
        {label && (
          <Button
            onClick={handleClick}
            variant="muted"
            px={2}
            fontSize={0}
            sx={{
              border: '1px solid',
              borderColor: 'border',
            }}
          >
            <Flex as="span" alignItems="center">
              <Text pl={1} as="span">
                {label}
              </Text>
              <Text as={IoIosArrowForward} color="lightText" size={16} />
            </Flex>
          </Button>
        )}
        <Box pl={1}>
          <ContextImage background={entity['story-image-url']} />
        </Box>
      </Flex>
    </Card>
  );
};

Notification.propTypes = {
  markSingleNotificationRead: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  id: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  onClose: () => {},
};

export default connect(null, { markSingleNotificationRead })(Notification);
