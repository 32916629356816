import { Redirect } from 'react-router-dom';
import React, { useMemo } from 'react';

import { Title } from '../../../app/modal/v2/styled-info';
import { getCollectionStoryRequestShares } from '../../../story-requests/api';
import { useEntity, useQueryEntities } from '../../../entities/hooks';
import { useHistory, useMatch } from '../../../app/hooks';
import Modal from '../../../app/modal/v2';
import StoryRequestForm from './form';
import StoryRequestsList from './list';

const StoryRequestsModal = () => {
  const { params, url } = useMatch();
  const { replace } = useHistory();
  const { entity, isOwner } = useEntity(params);

  const { loading } = useQueryEntities({
    query: () => getCollectionStoryRequestShares(params.id),
  });

  const hasStoryRequests = useMemo(
    () => entity['story-request-shares'] && entity['story-request-shares'].length,
    [entity]
  );

  if (loading) return false;

  if (!hasStoryRequests && isOwner) {
    return <Redirect to={`${url}/new`} />;
  }

  return (
    <Modal
      onClose={() =>
        replace(
          `${url
            .split('/')
            .slice(0, -1)
            .join('/')}`
        )
      }
      closeButton
      aboveTooltip
    >
      <Title style={{ margin: '20px 0 10px' }}>Story Requests</Title>
      <StoryRequestForm>
        <StoryRequestsList loading={loading} />
      </StoryRequestForm>
    </Modal>
  );
};

export default StoryRequestsModal;
