import FontAwesome from 'react-fontawesome';
import React from 'react';

import { ContactMethod, ContactMethodsWrap, TableHeader, StyledLink } from './styled';

const AboutLifeTales = () => (
  <ContactMethodsWrap>
    <TableHeader>About LifeTales</TableHeader>
    <ContactMethodsWrap>
      <ContactMethod>
        <StyledLink href="https://lifetales.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Use <FontAwesome name="external-link" />
        </StyledLink>
      </ContactMethod>
      <ContactMethod>
        <StyledLink href="https://lifetales.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy <FontAwesome name="external-link" />
        </StyledLink>
      </ContactMethod>
      <ContactMethod>
        <StyledLink
          href="https://help.lifetales.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQ <FontAwesome name="external-link" />
        </StyledLink>
      </ContactMethod>
      <ContactMethod>
        <StyledLink
          href="https://help.lifetales.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help <FontAwesome name="external-link" />
        </StyledLink>
      </ContactMethod>
      <ContactMethod>
        <StyledLink href="mailto:hello@lifetales.com">
          Contact Us <FontAwesome name="external-link" />
        </StyledLink>
      </ContactMethod>
    </ContactMethodsWrap>
  </ContactMethodsWrap>
);

export default AboutLifeTales;
