import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AvatarList from '../../../members/avatar-list';
import { selectRelatedEntities } from '../../../entities/selectors';

const CollectionItemAvatars = ({ memberIds, id }) => (
  <AvatarList
    hasOverlay={false}
    memberIds={memberIds}
    truncateAt={3}
    avatarSize={36}
    keyPrefix={id}
  />
);

export const selectCollectionMembers = ({ state, id, type }) => {
  const relatedType = `${type.slice(0, -1)}-shares`;
  const collectionShares = selectRelatedEntities({
    state, id, type, relatedType,
  });
  return (collectionShares || [])
    .sort(a => (a['contact-role'] === 'owner' ? -1 : 0))
    .map(({ 'with-contact': withContact }) => withContact.id);
};

const mapStateToProps = (state, { id, type }) => ({
  memberIds: selectCollectionMembers({ state, id, type }),
});

CollectionItemAvatars.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};
export default connect(mapStateToProps)(CollectionItemAvatars);
