import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// import useUserStoryShareFromStory from 'story-shares/hooks/index';
import { useHasStoryPermission } from 'stories/permission/index';
import { FaRegBell, FaRegBellSlash } from 'react-icons/fa';
import { useIsStoryMuted } from 'stories/hooks';
import CircleIcon from 'app/icons/circle-icon';
import { ReactComponent as CollectionIcon } from './assets/rounded-journal.svg';

import { ReactComponent as CalendarIcon } from './assets/rounded-calendar.svg';
import { ReactComponent as CopyIcon } from './assets/circle-duplicate.svg';
import { ReactComponent as CoverIcon } from './assets/rounded-cover.svg';
import { ReactComponent as DeleteIcon } from './assets/circle-trash.svg';
import { ReactComponent as DownloadIcon } from './assets/circle-download.svg';
import { ReactComponent as TagIcon } from './assets/rounded-tag.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { Wrap, TopButtonWrap, TopButton, RowButton, CoverPhoto, LightText } from './styled';
import Chip from './chip';
import Modal from '../../app/modal/v3';
import { useEntity } from '../../entities/hooks';
import { useStoryCover, useStoryDate } from '../hooks';
import { StoryOptionsContext } from './index';
import { useOwnerSharesStoryShares } from '../../story-shares/form/hooks';
import { useCollectionTitle } from '../../collections/shared/title';

const MutedIcon = () => (
  <CircleIcon size={44} mb={1} bg="#ededed">
    <FaRegBellSlash size={17} style={{ height: 'auto', width: 'auto', marginBottom: 0 }} />
  </CircleIcon>
);

const UnMutedIcon = () => (
  <CircleIcon size={44} mb={1} bg="#ededed">
    <FaRegBell size={17} style={{ height: 'auto', width: 'auto', marginBottom: 0 }} />
  </CircleIcon>
);

const CollectionNameChip = ({ type, id }) => {
  const title = useCollectionTitle({ type, id });
  return <Chip text={title} />;
};
CollectionNameChip.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const OptionsHome = () => {
  const { setCurrentModal, storyId, onClose } = useContext(StoryOptionsContext);
  const { story } = useEntity({ type: 'stories', id: storyId });

  const onStoryFormClick = () => {
    setCurrentModal('storyForm');
  };
  const {
    entity: {
      title,
      'tag-names': tagNames,
      'created-at': createdAt,
      'began-on': beganOn,
      'ended-on': endedOn,
    },
  } = useEntity({ type: 'stories', id: storyId });
  const cover = useStoryCover({ type: 'stories', id: storyId });
  const date = useStoryDate({ 'created-at': createdAt, 'began-on': beganOn, 'ended-on': endedOn });
  const storyShares = useOwnerSharesStoryShares(storyId);
  // const storyShare = useUserStoryShareFromStory(storyId) || {};
  const canDelete = useHasStoryPermission({ feature: 'DELETE_STORY', id: storyId });
  const isMuted = useIsStoryMuted(storyId);

  const links = [
    {
      icon: <ShareIcon />,
      label: `Share (${storyShares ? storyShares.length : 0})`,
      modal: 'shareOptions',
    },
    {
      icon: isMuted ? <MutedIcon /> : <UnMutedIcon />,
      label: isMuted ? 'Unmute' : 'Mute',
      modal: 'mute',
    },
    {
      icon: <DeleteIcon />,
      label: 'Delete',
    },
    {
      icon: <CopyIcon />,
      label: 'Copy',
    },
    {
      icon: <DownloadIcon />,
      label: 'Download',
    },
  ];

  return (
    <Modal close={{ onClose }} title="Story Options">
      <Wrap>
        <TopButtonWrap>
          {links
            .filter(link => {
              if (link.label === 'Delete') {
                return canDelete;
              }
              return true;
            })
            .map(link => (
              <TopButton
                key={link.label}
                onClick={() => setCurrentModal(link.modal || link.label.toLowerCase())}
              >
                {link.icon}
                {link.label}
              </TopButton>
            ))}
        </TopButtonWrap>
        <RowButton onClick={onStoryFormClick}>
          <div className="icon">
            <CoverIcon />
            Cover
          </div>
          {cover && <CoverPhoto src={cover} alt="cover photo" />}
          {title}
        </RowButton>
        <RowButton onClick={onStoryFormClick}>
          <div className="icon">
            <CalendarIcon />
            Date
          </div>
          {date || <LightText>Add a Story Date</LightText>}
        </RowButton>
        <RowButton onClick={() => setCurrentModal('tags')}>
          <div className="icon">
            <TagIcon />
            Tags
          </div>
          {isEmpty(tagNames) ? (
            <LightText>Add Story Tags</LightText>
          ) : (
            tagNames.map(tag => <Chip text={tag} variant="secondary" key={tag} />)
          )}
        </RowButton>
        <RowButton onClick={() => setCurrentModal('collectionShares')}>
          <div className="icon">
            <CollectionIcon />
            Collections
          </div>
          {(story.journals || []).map(collection => (
            <CollectionNameChip type={collection.type} key={collection.id} id={collection.id} />
          ))}
        </RowButton>
      </Wrap>
    </Modal>
  );
};

export default OptionsHome;
