
import { Image, Button, Text, Box, Card, Flex, Heading } from 'rebass';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { jsonApiReferenceShape } from '../../app/shapes';
import { useEntity } from '../../entities/hooks';
import MilestoneDate from './date';
import MilestoneLocation from './location';
import { useMatch, useHistory } from '../../app/hooks';
import { useUpdateTooltip } from '../tooltips';

const MilestoneCardThumbnail = ({ params }) => {
  const { image, isPresent } = useEntity(params);
  if (!isPresent) return false;
  const src = get(image, '["image-versions"][0].url', null);
  return <Image height={75} width={75} src={image.tmpUrl || src} style={{ objectFit: 'cover' }} />;
};
MilestoneCardThumbnail.propTypes = {
  params: jsonApiReferenceShape,
};
MilestoneCardThumbnail.defaultProps = {
  params: {
    type: 'images',
    id: '',
  },
};

export const MilestoneCard = React.memo(
  ({ title, startDate, endDate, location, note, imageId, onClick }) => (
    <Button
      as={Card}
      type="button"
      bg="white"
      borderRadius={8}
      onClick={onClick}
      css="
      padding: 20px;
      margin-bottom: 10px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0,0,0,0.15);
      transition: box-shadow .3s ease;
      :hover { box-shadow: rgba(0, 0, 0, 0.15) 0 3px 10px; }
      :active { transform: translate(0px, 2px); outline: 3px solid #BFB2FF; }
    "
    >
      <Flex color="grey20" bg="white">
        <Box mr="auto">
          <Heading color="grey20" fontSize={18} mb={2} textAlign="left">
            {title}
          </Heading>
          <Flex>
            <MilestoneDate startDate={startDate} endDate={endDate} mr={[2, 3]} />
            <MilestoneLocation location={location} />
          </Flex>
          {note && (
            <Text fontSize={12} color="mutedText" mt={2} textAlign="left" fontWeight="normal">
              {note}
            </Text>
          )}
        </Box>
        <MilestoneCardThumbnail params={{ type: 'images', id: imageId }} />
      </Flex>
    </Button>
  )
);
MilestoneCard.propTypes = {
  title: PropTypes.string.isRequired,
  note: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  imageId: PropTypes.string,
  onClick: PropTypes.func,
};
MilestoneCard.defaultProps = {
  note: null,
  startDate: null,
  endDate: null,
  location: null,
  imageId: '',
  onClick: () => {},
};

const MilestoneListItem = ({ shareId }) => {
  const { url } = useMatch();
  const { replace } = useHistory();
  const { milestoneShare } = useEntity({ type: 'milestone-shares', id: shareId });
  const { milestone } = useEntity({
    type: 'milestones',
    id: get(milestoneShare, 'milestone.id', ''),
  });
  const updateTooltip = useUpdateTooltip();

  return (
    <MilestoneCard
      title={milestone.title}
      startDate={milestone['start-date']}
      endDate={milestone['end-date']}
      location={milestone.location}
      note={milestone.note}
      imageId={milestone['cover-image'] && milestone['cover-image'].id}
      onClick={() => {
        replace(`${url}/life-events/${milestone.id}`);
        updateTooltip('VIEW_MILESTONE', { complete: true });
      }}
    />
  );
};
MilestoneListItem.propTypes = {
  shareId: PropTypes.string.isRequired,
};

export default MilestoneListItem;
