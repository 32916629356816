import { Flex, Heading, Button } from 'rebass';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { withProps } from 'recompose';
import FullPageFormWrap from './wrap';

const Header = styled(Flex)`
  height: 60px;

  button {
    display: inline-flex;
    border: none;
  }
`;

const HeaderButton = withProps({
  type: 'button',
  color: 'defaultText',
  variant: 'transparent',
})(styled.button`
  position: absolute;
`);

const FullPageForm = ({ title, onBackClick, onExitClick, children }) => (
  <FullPageFormWrap>
    <Header alignItems="center">
      {onBackClick && (
        <HeaderButton as={Button} onClick={onBackClick}>
          <IoIosArrowBack size={22} />
        </HeaderButton>
      )}
      {onExitClick && (
        <HeaderButton as={Button} onClick={onExitClick}>
          <IoMdClose size={22} />
        </HeaderButton>
      )}
      {title && (
        <Heading mx="auto" fontWeight="normal" fontSize={20}>
          {title}
        </Heading>
      )}
    </Header>
    {children}
  </FullPageFormWrap>
);

FullPageForm.propTypes = {
  title: PropTypes.string,
  onBackClick: PropTypes.func,
  onExitClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

FullPageForm.defaultProps = {
  title: '',
  onBackClick: null,
  onExitClick: null,
};

export default FullPageForm;
