import { Adopt } from 'react-adopt';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { CurrentCollectionContext } from '../../current-collection-context';
import { Tablet, DesktopSmall } from '../../../app/responsive';
import { selectCollectionMembers } from '../../list/item/avatars';
import { useCollectionTypeName } from '../../hooks';
import AvatarList from '../../../members/avatar-list';

const mapper = {
  desktop: <DesktopSmall />,
  tablet: <Tablet />,
};

const CollectionHeaderAvatars = ({ memberIds, id, ...props }) => {
  const { currentCollection } = useContext(CurrentCollectionContext);
  const collectionName = useCollectionTypeName(currentCollection.type);

  return (
    <Adopt mapper={mapper}>
      {({ desktop, tablet }) => (
        <AvatarList
          memberIds={memberIds}
          truncateAt={(desktop && 4) || (tablet && 3) || 2}
          avatarSize={36}
          keyPrefix={id}
          overlayTitle={`${collectionName} Participants`}
          {...props}
        />
      )}
    </Adopt>
  );
};

const mapStateToProps = (state, { id, type }) => ({
  memberIds: selectCollectionMembers({ state, id, type }),
});

CollectionHeaderAvatars.propTypes = {
  memberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(CollectionHeaderAvatars);
