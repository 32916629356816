import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import React from 'react';
import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';
import { underscore } from 'inflected';

import { postMilestone } from './api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import MilestoneForm from './form';
import Modal from '../../app/modal/v2';
import { useMatch, useHistory } from '../../app/hooks';
import { prepareAttrs } from '../../utils/api';
import NewMilestoneHeader from './form/header';
import milestoneTypes from './form/milestoneTypes';
import { useCreateCover } from '../../collections/hooks';
import { getCollection } from '../../collections/actions';

const CreateMilestone = () => {
  const {
    params: { id, milestoneType },
  } = useMatch();
  const { replace } = useHistory();
  const { memoir } = useEntity({ type: 'memoirs', id });
  const { receive } = useEntityActionCreators();
  const { title, placeholder } = milestoneTypes.find(type => type.param === milestoneType);
  const createCover = useCreateCover();
  const dispatch = useDispatch();

  return (
    <Modal closeButtonLeft onClose={() => replace(`/memoirs/${id}`)} aboveTooltip>
      <Formik
        initialValues={{
          'title-noac': '',
          'location-noac': '',
          'start-date': '',
          'end-date': '',
          'note-noac': '',
          cover: '',
        }}
        validationSchema={object().shape({
          'title-noac': string().required('Title is required'),
          'start-date': string().required(),
        })}
        onSubmit={async values => {
          const attributes = prepareAttrs({
            ...values,
            'milestone-kind': underscore(milestoneType),
            'lock-version': memoir['lock-version'],
          });
          const updatedAttributes = omit(attributes, ['cover']);

          // POST milestone
          const payload = await postMilestone({ memoirId: id, attributes: updatedAttributes });
          receive({ payload });

          if (values.cover instanceof Blob) {
            const newMilestoneId = Object.values(payload.data.entities.milestones)[0].id;
            await createCover({ id: newMilestoneId, type: 'milestones', cover: values.cover });
          }

          dispatch(getCollection({ type: 'memoirs', id }));
          replace(`/memoirs/${id}`);
        }}
        render={formikProps => (
          <Form>
            <NewMilestoneHeader ctaText="Add" title={title} isValid={formikProps.isValid} />
            <MilestoneForm {...formikProps} titlePlaceholder={placeholder} />
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateMilestone;
