
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';
import { FaPlay } from 'react-icons/fa';
import CollectionOnboardSlide from '../slide';
import bg1 from './assets/bg-1.png';
import bg2 from '../assets/bg-2.png';
import bg3 from '../assets/bg-3.png';
import bg4 from '../assets/bg-4.png';
import img2 from './assets/img-2.png';
import img3 from './assets/img-3.png';
import img4 from './assets/img-4.png';

export const SlideOne = ({ setShowVideo }) => (
  <CollectionOnboardSlide
    header="The Grandparents"
    body={
      <>
        <p>
          Learn more about the life of the grandparents. What was life like when they were young?
          What do they want to tell their grandkids?
        </p>
        <Button
          onClick={() => setShowVideo(true)}
          variant="primary"
          mt="auto"
          mb="60px"
          css="display: flex; align-items: center;"
        >
          <FaPlay style={{ marginRight: '4px' }} /> Watch Video
        </Button>
      </>
    }
    bg={bg1}
  />
);

SlideOne.propTypes = {
  setShowVideo: PropTypes.func.isRequired,
};

export const SlideTwo = () => (
  <CollectionOnboardSlide
    header="Leave a Legacy"
    body="Everyone can view, comment on, and like all stories as well as add their own."
    bg={bg2}
    image={img2}
  />
);

export const SlideThree = () => (
  <CollectionOnboardSlide
    header="Family Fun"
    body="Tell the grandkids about what is going on behind the photo, even if they don’t get it!"
    bg={bg3}
    image={img3}
  />
);

export const SlideFour = () => (
  <CollectionOnboardSlide
    header="Easy Archiving"
    body="We have a tag for every stage of life. LifeTales helps you to find stories easily!"
    bg={bg4}
    image={img4}
  />
);
