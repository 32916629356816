

import { Flex, Button, Heading, Text } from 'rebass';
import { get } from 'lodash';
import { useMedia } from 'the-platform';
import React, { useContext, useMemo } from 'react';

import { getResourcePath } from 'collections/api';
import useSWR from 'swr';
import { CollectionTooltipContext } from '../../collections/collection-tooltip-context';
import { sizes } from '../../app/styles/theme';
import { useCurrentMember } from '../../members/hooks';
import { useEntity, useEntities } from '../../entities/hooks';
import { useHistory, useMatch, useLocation } from '../../app/hooks';
import MemoirTooltip, { useUpdateTooltip } from '../tooltips';
import MilestoneListItem from './card';
import Modal from '../../app/modal/v2';

const useMilestoneShares = (id, sortAttr = 'start-date') => {
  const path = getResourcePath({ id, type: 'memoirs' });
  useSWR(path);
  const { memoir, currentMemberId } = useEntity({ type: 'memoirs', id });
  const shares = useEntities('milestone-shares');
  const milestones = useEntities('milestones');

  return useMemo(
    () =>
      (memoir['milestone-shares'] || [])
        .filter(share => get(shares, `[${share.id}]['with-contact'].id`, '') === currentMemberId)
        .sort((a, b) => {
          const { [a.id]: shareA, [b.id]: shareB } = shares;
          const {
            [shareA.milestone.id]: milestoneA,
            [shareB.milestone.id]: milestoneB,
          } = milestones;

          return new Date(milestoneA[sortAttr]) - new Date(milestoneB[sortAttr]);
        }),
    [memoir, shares, currentMemberId, milestones, sortAttr]
  );
};

const CollectionMilestonesList = () => {
  const {
    url,
    params: { id },
  } = useMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { currentTooltip } = useContext(CollectionTooltipContext);
  const updateTooltip = useUpdateTooltip();
  const sharesByStartDate = useMilestoneShares(id);
  const sharesByCreatedAt = useMilestoneShares(id, 'created-at');
  const { 'first-name': firstName } = useCurrentMember();
  const isDesktop = useMedia({ minWidth: `${sizes.desktop}px` });

  const shouldShowCongratsModal =
    !pathname.includes('life-events') &&
    currentTooltip.type === 'ADD_THREE_MILESTONES' &&
    sharesByStartDate.length > 3;

  const handleNewMilestoneClick = () => {
    updateTooltip('ADD_MILESTONE', { complete: true });
    push(`${url}/life-events/new`);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        px={[2, 3]}
        pt={[2, 3]}
        pb="100px"
        css="max-width: 576px;"
        m="auto"
      >
        {sharesByStartDate.map(({ id: shareId, type }) => {
          return (
            <MemoirTooltip
              type="VIEW_MILESTONE"
              text="Here is the event you just added. Tap on it to check out how it looks!"
              key={`${shareId}${type}`}
              isEnabled={shareId === get(sharesByCreatedAt, '[1].id', '')}
            >
              <MilestoneListItem shareId={shareId} />
            </MemoirTooltip>
          );
        })}
        <Flex
          justifyContent="center"
          css={`
            position: fixed;
            left: ${isDesktop ? '270px' : '0'};
            right: 0;
            bottom: 0;
            z-index: 101;
          `}
          p={3}
          pb={[5, 5, 5, 3]}
        >
          <MemoirTooltip
            type="ADD_MILESTONE"
            text="You can add major life events to the timeline here!"
          >
            <Button onClick={handleNewMilestoneClick} variant="primary" py={3} px={5}>
              Add To Timeline
            </Button>
          </MemoirTooltip>
        </Flex>
      </Flex>
      {shouldShowCongratsModal && (
        <Modal aboveTooltip>
          <Flex flexDirection="column" p={4} css="max-width: 576px; text-align: center;">
            <span role="img" aria-label="tada emoji" style={{ fontSize: '60px' }}>
              🎉
            </span>
            <Heading mb={3}>Good job, {firstName}!</Heading>
            <Text my={3}>
              Now that you&apos;ve got the ball rolling, you can <strong>invite others</strong> to
              the memoir!
            </Text>
            <Text>
              <strong>Memoir participants</strong> are able to view all stories, contribute their
              own, ask questions and like & comment on stories!
            </Text>
            <Button
              onClick={() =>
                updateTooltip('ADD_THREE_MILESTONES', { complete: true, dismiss: true })
              }
              variant="primary"
              mt={4}
              css="max-width: 300px;"
              mx="auto"
            >
              Invite Now
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default CollectionMilestonesList;
