import useSWR from 'swr';
import { get, destroy, post, patch } from '../utils/api';

export const getStory = storyId => get(`/v2/stories/${storyId}`);
export const useStorySWR = storyId => useSWR(`/v2/stories/${storyId}`);
export const removeMediumActivity = id => destroy(`/v2/media_activities/${id}`);
export const addMediumActivity = (mediumId, body) =>
  post(`/v2/media/${mediumId}/media_activities`, body);
export const updateMediumActivity = (mediumId, body) =>
  patch(`/v2/media_activities/${mediumId}`, body);
export const createStory = attributes => post('/v2/stories', { data: { attributes } });
export const updateStory = (id, attributes) => patch(`/v3/stories/${id}`, { data: { attributes } });
export const destroyStory = storyId => destroy(`/v2/stories/${storyId}`);
export const getPublicStory = (memberId, storyId) =>
  get(`/v2/public/members/${memberId}/stories/${storyId}`);
export const downloadStory = storyId => patch(`/v2/stories/${storyId}/export`);
export const shareStory = storyId => patch(`/v2/stories/${storyId}/share_now`);
export const addStoryTag = ({ journalId, storyId, name, favourite = true }) =>
  post(
    `/v3/journals/${journalId}/stories/${storyId}/tags`,
    { name, favourite },
    { normalize: false }
  );
export const removeStoryTag = ({ journalId, storyId, name }) =>
  destroy(`/v3/journals/${journalId}/stories/${storyId}/tags/${name}`, {});
export const sendStoryUpdate = id => patch(`/v3/stories/${id}/notify`, {});
