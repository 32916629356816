import { UserAgent } from '@quentin-sommer/react-useragent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import * as loadImage from 'blueimp-load-image';

import { CoverWrapper } from './styled';
import { acceptedFor } from '../../../story-media/helper';
import { createStoryCoverMedium } from '../../../story-media/actions';
import { getCoverUrl } from '../../helper';
import { selectStory, selectStoryCover } from '../../selectors';
import FileInput from '../../../app/form/hidden-file-input';
import addIcon from '../../../assets/svg/add-photo.svg';

const CoverPhoto = ({ image, story, ...props }) => {
  const imageUrl = getCoverUrl(story, image);
  return (
    <CoverWrapper imageUrl={imageUrl}>
      <UserAgent android>
        {isAndroid => (
          <FileInput
            id="upload_cover"
            accept={isAndroid ? 'image/*' : acceptedFor.images}
            onChange={e => {
              e.preventDefault();
              const file = e.target.files[0];
              loadImage(
                file,
                canvas => {
                  canvas.toBlob(
                    blob => {
                      const newImage = blob;
                      newImage.name = file.name;
                      props.createStoryCoverMedium(story.id, newImage);
                    },
                    file.type,
                    85
                  );
                },
                {
                  maxWidth: 1542,
                  maxHeight: 1542,
                  orientation: true,
                }
              );
            }}
          />
        )}
      </UserAgent>
      <label htmlFor="upload_cover">
        <img src={addIcon} alt="" />
        <span>{imageUrl ? 'Change your' : 'Add a'} Cover Photo</span>
      </label>
    </CoverWrapper>
  );
};

CoverPhoto.propTypes = {
  createStoryCoverMedium: PropTypes.func.isRequired,
  story: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    'upload-url': PropTypes.string,
    started: PropTypes.bool,
    tmpFile: PropTypes.instanceOf(Blob),
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  story: selectStory(state, ownProps.id),
  image: selectStoryCover(state, ownProps.id) || {},
});

export default connect(
  mapStateToProps,
  { createStoryCoverMedium }
)(CoverPhoto);
