import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { omit, get } from 'lodash';

import { patchMilestone, getMilestone } from './api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import MilestoneForm from './form';
import Modal from '../../app/modal/v2';
import { useMatch, useHistory } from '../../app/hooks';
import { prepareAttrs } from '../../utils/api';
import NewMilestoneHeader from './form/header';
import { useCreateCover } from '../../collections/hooks';
import { destroyStoryMedium } from '../../story-media/api';
import DeleteCoverImage from './form/delete-cover-image';
import { useJsDate } from '../../app/hooks/index';

const EditMilestone = () => {
  const {
    params: { id, milestoneId },
  } = useMatch();
  const { replace } = useHistory();
  const { entity: milestone } = useEntity({ type: 'milestones', id: milestoneId });
  const { receive, receiveDeletion, update } = useEntityActionCreators();
  const createCover = useCreateCover();
  const milestoneCoverId = get(milestone, "['cover-image'].id", null);
  const {
    image: { id: currentCoverId },
  } = useEntity({ type: 'images', id: milestoneCoverId });
  const [confirmDestroyImage, setConfirmDestroyImage] = useState(false);
  const destroyMedium = () =>
    destroyStoryMedium(currentCoverId).then(() => {
      receiveDeletion({ entityType: 'images', id: currentCoverId });
      update({ entityType: 'milestones', id, attrs: { 'cover-image': null } });
      setConfirmDestroyImage(false);
    });
  const initialStartDate = useJsDate(milestone['start-date']);
  const initialEndDate = useJsDate(milestone['end-date']);

  return (
    <Modal closeButtonLeft onClose={() => replace(`/memoirs/${id}`)} aboveTooltip>
      <DeleteCoverImage
        isVisible={confirmDestroyImage}
        onClick={destroyMedium}
        onCancel={() => setConfirmDestroyImage(false)}
        text="Are you sure you want to delete this image?"
      />
      <Formik
        initialValues={{
          'title-noac': milestone.title || '',
          'location-noac': milestone.location || '',
          'start-date': initialStartDate || '',
          'end-date': initialEndDate || '',
          'note-noac': milestone.note || '',
          cover: '',
        }}
        validationSchema={object().shape({
          'title-noac': string().required('Title is required'),
          'start-date': string().required('Start Date is required'),
        })}
        onSubmit={async values => {
          // TODO get latest lock version because async cover generation
          const lockVersion = await getMilestone({ memoirId: id, milestoneId: milestone.id }).then(
            payload => {
              const [latestMilestone] = Object.values(payload.data.entities.milestones);
              return latestMilestone['lock-version'];
            }
          );
          const attributes = prepareAttrs({
            ...values,
            'lock-version': lockVersion,
          });
          const updatedAttributes = omit(attributes, ['cover']);

          // PATCH milestone
          const payload = await patchMilestone({
            memoirId: id,
            milestoneId,
            attributes: updatedAttributes,
          });
          receive({ payload });
          if (values.cover instanceof Blob) {
            await createCover({ id: milestoneId, type: 'milestones', cover: values.cover });
          }

          replace(`/memoirs/${id}`);
        }}
        render={formikProps => (
          <Form>
            <NewMilestoneHeader ctaText="Save" title="Edit Event" isValid={formikProps.isValid} />
            <MilestoneForm
              {...formikProps}
              currentCoverId={currentCoverId}
              destroyCover={currentCoverId ? () => setConfirmDestroyImage(true) : null}
            />
          </Form>
        )}
      />
    </Modal>
  );
};

export default EditMilestone;
