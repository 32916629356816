import { PoseGroup } from 'react-pose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Wrap, Message } from './styled';
import { selectCheckmark } from '../selectors';
import { clearCheckmark } from '../actions';
import CheckIcon from './icon';

class Checkmark extends Component {
  componentDidMount() {
    const { message, visibleDuration, ...props } = this.props;
    if (message) {
      this.timeout = setTimeout(() => {
        props.clearCheckmark();
      }, visibleDuration);
    }
  }

  componentDidUpdate(prevProps) {
    const { message, visibleDuration, ...props } = this.props;
    if (message && prevProps.message !== message) {
      this.timeout = setTimeout(() => {
        props.clearCheckmark();
      }, visibleDuration);
    }
  }

  componentWillUnmount() {
    const { message, ...props } = this.props;
    clearTimeout(this.timeout);
    return message && props.clearCheckmark();
  }

  render() {
    const { top, message } = this.props;

    return (
      <PoseGroup>
        {message && (
          <Wrap key="checkmark" top={top}>
            <CheckIcon />
            <Message>{message}</Message>
          </Wrap>
        )}
      </PoseGroup>
    );
  }
}

Checkmark.propTypes = {
  top: PropTypes.string,
  message: PropTypes.string,
  clearCheckmark: PropTypes.func.isRequired,
  visibleDuration: PropTypes.number,
};

Checkmark.defaultProps = {
  message: '',
  top: 'calc(50vh - 75px)',
  visibleDuration: 1750,
};

const mapStateToProps = state => {
  const checkmark = selectCheckmark(state);
  return { ...checkmark };
};

export default connect(mapStateToProps, { clearCheckmark })(Checkmark);
