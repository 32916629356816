import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { TagContext } from '../../collections/detail/tag-context';
import StoryTags from '.';
import TagManagement from '../../collections/detail/tag-management';
import Modal from '../../app/modal/v3';

const StoryTagsManager = ({ storyId, collection, onClose, onSuccess }) => {
  const { replace } = useHistory();
  const [on, toggle] = useState();
  const [newTagDirty, setNewTagDirty] = useState(false);

  const confirmDirty = fn => {
    if (newTagDirty) {
      if (window.confirm('Are you sure? Clicking OK will cancel the new tag.')) {
        fn();
      }
    } else {
      fn();
    }
  };

  return (
    <Modal
      close={{ onClose: () => (onClose ? onClose() : replace(`/story/${storyId}`)) }}
      back={{ onBack: on ? confirmDirty(() => toggle(toggled => !toggled)) : null }}
      title="Story Tags"
      footerOffset={60}
    >
      {on && (
        <TagManagement
          collection={collection}
          onBackClick={() => confirmDirty(() => toggle(toggled => !toggled))}
          storyId={storyId}
        />
      )}
      {!on && (
        <StoryTags
          collection={collection}
          storyId={storyId}
          onSuccess={onSuccess}
          onToggle={() => confirmDirty(() => toggle(toggled => !toggled))}
          setNewTagDirty={setNewTagDirty}
        />
      )}
    </Modal>
  );
};

StoryTagsManager.propTypes = {
  collection: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  storyId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

StoryTagsManager.defaultProps = {
  collection: {
    type: '',
    id: '',
  },
  onClose: null,
};

export default StoryTagsManager;
