import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Box, Text } from 'rebass';
import Modal from 'app/modal/v3/index';

const StoryShareInfo = ({ onBack }) => (
  <Modal title="Collection Shares" back={{ onBack }}>
    <Box bg="accentSubtle" p={4}>
      <Text pb={4}>You can optionally invite people to your Collection.</Text>
      <Text>Participants will</Text>
      <Box as="ol" mb={0}>
        <Text as="li">see this Collection in their own sidebar</Text>
        <Text as="li">be able to view all stories in the Collection</Text>
        <Text as="li">be able to contribute their own stories.</Text>
      </Box>
    </Box>
    <Box p={4}>
      <Heading fontFamily="sans" pb={2} fontSize={3}>
        Invite your LifeTales contacts
      </Heading>
      <Text pb={4}>
        Privately invite your current LifeTales contacts. Select as many as you’d like.
      </Text>
      <Heading fontFamily="sans" pb={2} fontSize={3}>
        Send a link to non-LifeTales contacts
      </Heading>
      <Text pb={4}>
        Send this link to your friends by email, messaging apps or social media. Anyone with the
        link will be able to connect with you and join the Collection.
      </Text>
      <Heading fontFamily="sans" pb={2} fontSize={3}>
        More Story Sharing Options
      </Heading>
      <Text>
        You can also share individual stories you’ve created. Open a story and tap the share link to
        learn more.
      </Text>
    </Box>
  </Modal>
);
StoryShareInfo.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default StoryShareInfo;
