import { Flex, Heading, Box } from 'rebass';
import React from 'react';
import PropTypes from 'prop-types';
import useSWR, { useSWRPages } from 'swr';

import { get } from 'lodash';

import ActivateSubscriptionPopup from '../../subscriptions/activate-popup';
import Loading from '../../app/loading';
import StoryListEmptyMessage from '../list/empty';
import { useHasDrawer } from '../../app/mobile-drawer/hooks';
import StoryListItemWithCollections from '../list/item-with-collections';
import { getSWR } from '../../utils/api';
import LoadMoreButton from '../../app/pagination/load-more-button';
import getNextPaginatedPage from '../../app/pagination/getNextPaginatedPage';

const StoriesListPage = ({ notMine, title }) => {
  const { pages, loadMore, isReachingEnd, isLoadingMore } = useSWRPages(
    notMine ? 'shared-with-me' : 'my-stories',
    ({ offset, withSWR }) => {
      /* eslint-disable */
      const { data } = withSWR(
        useSWR(`v3/my/collection?not_mine=${notMine}&page=${offset || 1}&per=10`, getSWR)
      );
      /* eslint-enable */
      if (!data) {
        return (
          <Box p={4} css="width: 100%;">
            <Loading local />
          </Box>
        );
      }
      const shares = data ? get(data, "data.data.relationships['story-shares'].data", false) : [];

      if (!shares.length) {
        return <StoryListEmptyMessage isMyStories={!notMine} />
      }
      return shares.map(({ id, type }) => (
        <StoryListItemWithCollections key={id} id={id} type={type} />
      ));
    },
    getNextPaginatedPage
  );
  const hasDrawer = useHasDrawer();

  return (
    <Box mb={hasDrawer ? 70 : 0} p={3}>
      <Heading
        my={hasDrawer ? 1 : 2}
        mb={hasDrawer ? 1 : 2}
        ml={2}
        color="lightText"
        fontWeight="normal"
        fontFamily="sans"
        fontSize={[3, 3, 3, 4]}
      >
        {title}
      </Heading>
      <Flex flexWrap="wrap" alignItems="stretch">
        {pages}
        {!isLoadingMore && !isReachingEnd && (
          <LoadMoreButton disabled={isLoadingMore || isReachingEnd} loadMore={loadMore} />
        )}
        <ActivateSubscriptionPopup />
      </Flex>
    </Box>
  );
};

StoriesListPage.propTypes = {
  notMine: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default StoriesListPage;
