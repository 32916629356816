
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, Text, Button } from 'rebass';
import { useDispatch } from 'react-redux';
import Modal from '../modal/v2';
import { useCurrentMember } from '../../members/hooks';
import Avatar from '../../members/avatar';
import { useHistory } from '../hooks';
import FeatureList from './feature-list';
import { GradientHeader } from './styled';
import { setCheckmark } from '../../ui/actions';
import useMemberSubscription from '../../members/profile/subscription/useMemberSubscription';
import { useJSONAPIRequest } from '../json-api-client';

const FreeTrial = ({ onClose, productIdentifier }) => {
  const member = useCurrentMember();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { isTrialEligible, trialUrl } = useMemberSubscription(productIdentifier);

  const { data, mutate } = useJSONAPIRequest({
    method: 'post',
  });

  useEffect(() => {
    if (data) {
      dispatch(setCheckmark({ message: 'Trial Started' }));
      onClose();
    }
  }, [data, dispatch, onClose, push]);

  const handleClick = () => {
    if (isTrialEligible) {
      return mutate({}, trialUrl);
    }
    return onClose();
  };

  return (
    <Modal closeButtonLeft onClose={onClose} style={{ color: 'white' }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg="white"
        css="text-align: center; color: #333; border-radius: 12px;"
      >
        <GradientHeader>
          <Avatar id={member.id} size="100" />
          <Heading my={3}>Welcome to your free trial, {member['first-name']}!</Heading>
          <Text>
            You will be able to try out all the <strong>premium features</strong> that come from a
            subscription.
          </Text>
        </GradientHeader>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" my={3} p={3}>
          <FeatureList />
          <Button
            onClick={handleClick}
            variant="primary"
            mt={3}
            css="small { font-weight: normal; }"
          >
            Start free trial now
            <br />
            <small>(No credit card required)</small>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

FreeTrial.propTypes = {
  onClose: PropTypes.func.isRequired,
  productIdentifier: PropTypes.string.isRequired,
};

export default FreeTrial;
